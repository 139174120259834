import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../config/auth";
import { AppSettings } from "./../../config/app-settings.js";
import { useAuthStore } from "../../stores/auth";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import { SettingsContext } from "../../config/settings";
import { DEV_MODE } from "../../constants";

const createInitialValues = (token) => ({
  password: "",
  password_confirm: "",
  token: token,
});

const createValidationSchema = (t) =>
  Yup.object().shape({
    password: Yup.string().required(
      t("form.errors.required", { attr: t("auth.password") }),
    ),
    password_confirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "auth.password_confirm_error",
    ),
    token: Yup.string().required(),
  });

function ResetPassword() {
  const { t } = useTranslation();
  const settings = useContext(AppSettings);
  const { whitelabel } = useContext(SettingsContext);
  const [alerts, setAlerts] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [resetPassword] = useAuthStore(
    (state) => [state.resetPassword],
    shallow,
  );

  const [queryParams, setQueryhParams] = useSearchParams();
  const next = queryParams.get("next");
  const token = queryParams.get("t");

  useEffect(() => {
    settings.handleSetAppSidebarNone(true);
    settings.handleSetAppHeaderNone(true);
    settings.handleSetAppContentClass("p-0");

    return () => {
      settings.handleSetAppSidebarNone(false);
      settings.handleSetAppHeaderNone(false);
      settings.handleSetAppContentClass("");
    };
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    const { data, error } = await resetPassword({
      password: values.password,
      token: token,
    });
    if (!!error) {
      setErrors({ password_confirm: error.detail });
    } else {
      setRedirect("/");
    }
  };

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  if (!!redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span> {whitelabel.name}
            </div>
            <small>Außendienst Portal</small>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            {alerts.map((a, i) => (
              <div key={i} className="col">
                <div className={`alert alert-${a.type}`}>{t(a.t)}</div>
              </div>
            ))}
            <Formik
              initialValues={createInitialValues(token)}
              validationSchema={createValidationSchema(t)}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="mb-4">
                    <Field
                      type="password"
                      name="password"
                      className="form-control fs-13px h-45px is-invalid"
                      placeholder={t("auth.password")}
                    />
                    <ErrorMessage
                      name="password"
                      render={(error) => (
                        <div className="invalid-feedback">
                          <ul className="list-unstyled">
                            {Array.isArray(error) ? (
                              error.map((e, i) => <li key={i}>{t(e.t)}</li>)
                            ) : (
                              <li>{t(error)}</li>
                            )}
                          </ul>
                        </div>
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      type="password"
                      name="password_confirm"
                      className="form-control fs-13px h-45px is-invalid"
                      placeholder={t("auth.password_confirm")}
                    />
                    <ErrorMessage
                      name="password_confirm"
                      render={(error) => (
                        <div className="invalid-feedback">
                          <ul className="list-unstyled">
                            {Array.isArray(error) ? (
                              error.map((e, i) => <li key={i}>{t(e.t)}</li>)
                            ) : (
                              <li>{t(error)}</li>
                            )}
                          </ul>
                        </div>
                      )}
                    />
                  </div>

                  <div className="login-buttons">
                    <button
                      type="submit"
                      className="btn h-45px btn-red d-block w-100 btn-lg"
                      disabled={isSubmitting}
                    >
                      {t("auth.submit")}
                    </button>
                  </div>
                  {DEV_MODE && (
                    <pre
                      className="m-t-xl"
                      style={{
                        fontSize: "1rem",
                        padding: ".25rem .5rem",
                        overflowX: "scroll",
                      }}
                    >
                      VALUES: {JSON.stringify(values, null, 2)}
                      <br />
                      ERRORS: {JSON.stringify(errors, null, 2)}
                    </pre>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
