import request from "./client";

export const getById = async ({ id }) => {
  const resp = await request.get(`/v1/delivery_note_submissions/${id}`);
  return { data: resp.body };
};
export const getSome = async ({ q = "", limit = 10, offset = 0 }) => {
  try {
    const resp = await request.get(
      `/v1/delivery_note_submissions?limit=${limit}&offset=${offset}&q=${q}`,
    );
    const body = resp.body;
    return { total: body.meta.total, data: body.results, error: null };
  } catch (e) {
    if (!e.response) {
      return { total: 0, data: [], error: e };
    }
    return { total: 0, data: [], error: e.response.body };
  }
};
