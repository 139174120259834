import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  NavLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../config/auth";
import { AppSettings } from "./../../config/app-settings.js";
import { useAuthStore } from "../../stores/auth";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import { SettingsContext } from "../../config/settings";

const initialValues = {
  email: "",
  password: "",
  remember_me: false,
  otp: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  otp: Yup.string().optional(),
});

function Login() {
  const { t } = useTranslation();
  const settings = useContext(AppSettings);
  const { whitelabel, env } = useContext(SettingsContext);
  const { setToken } = useContext(AuthContext);
  const [me, login] = useAuthStore((state) => [state.me, state.login], shallow);

  const [queryParams, setQueryhParams] = useSearchParams();
  const next = queryParams.get("next");

  useEffect(() => {
    settings.handleSetAppSidebarNone(true);
    settings.handleSetAppHeaderNone(true);
    settings.handleSetAppContentClass("p-0");

    return () => {
      settings.handleSetAppSidebarNone(false);
      settings.handleSetAppHeaderNone(false);
      settings.handleSetAppContentClass("");
    };
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    const { data, error } = await login({
      username: values.email,
      password: values.password,
    });
    if (!!error) {
      setErrors({ password: error.detail });
    } else {
      setToken(data.access_token);
    }
  };

  if (!!me) {
    return <Navigate to={!!next ? next : "/"} />;
  }

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span> {whitelabel.name}
            </div>
            <small>Außendienst Portal</small>
          </div>
          {false && (
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          )}
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="mb-4">
                    <Field
                      type="email"
                      name="email"
                      className="form-control fs-13px h-45px is-invalid"
                      placeholder="Email Adresse"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      name="password"
                      type="password"
                      className="form-control fs-13px h-45px is-invalid"
                      placeholder="Passwort"
                    />
                    <ErrorMessage
                      name="password"
                      render={(error) => (
                        <div className="invalid-feedback">
                          <ul className="list-unstyled">
                            {Array.isArray(error) ? (
                              error.map((e, i) => <li key={i}>{t(e.t)}</li>)
                            ) : (
                              <li>{t(error)}</li>
                            )}
                          </ul>
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-check mb-4">
                    <Field
                      name="remember_me"
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="remember_me">
                      {t("auth.remember_me")}
                    </label>
                    <NavLink
                      to="/auth/forgot"
                      className="float-end text-danger text-decoration-none"
                    >
                      {t("auth.forgot_password")}
                    </NavLink>
                  </div>

                  <div className="login-buttons">
                    <button
                      type="submit"
                      className="btn h-45px btn-red d-block w-100 btn-lg"
                    >
                      {t("auth.sign_in")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
