import { create } from "zustand";
import produce from "immer";
import * as api from "../services/auth";

export const Role = {
  ADMINISTRATOR: "administrator",
  MANAGER: "manager",
  TECHNICIAN: "technician",
};

export const useAuthStore = create((set, get) => ({
  me: null,
  isLoading: true,
  login: async ({ username, password }) => {
    // grant_type=&username=${values.email}&password=${values.password}&scope=&client_id=&client_secret=
    return await api.login({ username, password });
  },
  logout: async () => {
    return await api.logout();
  },
  fetchMe: async (token) => {
    set(
      produce((draft) => {
        draft.isLoading = true;
      }),
    );
    const resp = await api.getMe(token);
    set(
      produce((draft) => {
        draft.me = resp.data;
        draft.isLoading = false;
      }),
    );
    return resp;
  },
  hasRole: (name) => {
    const state = get();
    if (!state.me) {
      return false;
    }
    return state.me.roles.map((r) => r.name).includes(name);
  },
  forgotPassword: async ({ email }) => {
    return await api.forgotPassword({ email });
  },
  resetPassword: async ({ token, password }) => {
    return await api.resetPassword({ token, password });
  },
}));
