import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useItemStore } from "../../stores/items";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import { useCustomerStore } from "../../stores/customers";

function Customer({ customer }) {
  return (
    <tr>
      <td className="w-10px align-middle">
        <div className="form-check">
          <input type="checkbox" className="form-check-input" id="product1" />
          <label className="form-check-label" htmlFor="product1"></label>
        </div>
      </td>
      <td>
        <Link to={`${customer.id}/edit`} className="fw-bold">
          {customer.number}
        </Link>
      </td>
      <td>
        {/*<Link*/}
        {/*  to={`${customer.id}/details`}*/}
        {/*  className="text-dark text-decoration-none"*/}
        {/*>*/}
        {/*  {customer.name}*/}
        {/*</Link>*/}
        {customer.name}
      </td>
      <td>
        <ul className="list-group list-unstyled">
          {customer.addresses.map((address) => (
            <li key={address.id}>
              <ul className="list-group list-unstyled">
                {!!address.street && <li>{address.street}</li>}
                {!!address.zip && (
                  <li>
                    {address.zip} {address.city} <i className="fa fa-map-pin" />
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </td>
      <td>
        <ul className="list-group list-unstyled">
          {customer.contacts.map((contact) => (
            <li key={contact.id}>
              <ul className="list-group list-unstyled">
                {!!contact.occupation && <li>{contact.occupation}</li>}
                <li>
                  {!!contact.salutation ? (
                    <>
                      {contact.salutation} {contact.name}
                    </>
                  ) : (
                    contact.name
                  )}
                </li>
                {!!contact.email && (
                  <li>
                    <i className="fa fa-envelope" />{" "}
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </li>
                )}
                {!!contact.phone && (
                  <li>
                    <i className="fa fa-phone" />
                    <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                  </li>
                )}
                {!!contact.mobile && (
                  <li>
                    <i className="fa fa-mobile-phone" />{" "}
                    <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </td>
    </tr>
  );
}

function Customers() {
  const { t } = useTranslation();
  const [queryParams, setQueryhParams] = useSearchParams();

  const [byIds, allIds, total, fetchSome] = useCustomerStore(
    (state) => [state.byIds, state.allIds, state.total, state.fetchSome],
    shallow,
  );

  const customers = allIds.map((id) => byIds[id]);

  const search = queryParams.get("q") || "";
  const limit = parseInt(queryParams.get("limit")) || 10;
  const pages = Math.ceil(total / limit);
  const page = parseInt(queryParams.get("page")) || 0;
  const offset = page * limit;

  useEffect(() => {
    fetchSome({ q: search, limit, offset });
  }, [search, page, limit]);

  const handleSearch = (e) => {
    e.preventDefault();
    setQueryhParams(`q=${e.target.value}`);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3 mb-3 mx-3 mx-md-0">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/customers">{t("customers.customers")}</Link>
            </li>
          </ol>
          <h1 className="page-header mb-0">{t("customers.customers")}</h1>
        </div>
        {false && (
          <div className="ms-auto">
            <a href="#/" className="btn btn-red btn-rounded px-4 rounded-pill">
              <i className="fa fa-plus fa-lg me-2 ms-n2"></i> {t("menu.add")}
            </a>
          </div>
        )}
      </div>

      <div className="mb-3 mx-3 mx-md-0 d-sm-flex fw-bold">
        <div className="mt-sm-0 mt-2">
          <a href="#/" className="text-dark text-decoration-none">
            <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.export")}
          </a>
        </div>
        <div className="ms-sm-4 ps-sm-1 mt-sm-0 mt-2">
          <a href="#/" className="text-dark text-decoration-none">
            <i className="fa fa-upload fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.import")}
          </a>
        </div>
        <div className="ms-sm-4 ps-sm-1 mt-sm-0 mt-2 dropdown-toggle">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            className="text-dark text-decoration-none"
          >
            <i className="fa fa-cog fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.more")} <b className="caret"></b>
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              Action
            </a>
            <a className="dropdown-item" href="#/">
              Another action
            </a>
            <a className="dropdown-item" href="#/">
              Something else here
            </a>
            <div role="separator" className="dropdown-divider"></div>
            <a className="dropdown-item" href="#/">
              Separated link
            </a>
          </div>
        </div>
      </div>

      <div className="card border-0">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
          <li className="nav-item me-2">
            <a
              href="#allTab"
              className="nav-link active px-2"
              data-bs-toggle="tab"
            >
              {t("menu.all")}
            </a>
          </li>
          <li className="nav-item me-2">
            <a
              href="#deletedTab"
              className="nav-link px-2"
              data-bs-toggle="tab"
            >
              {t("menu.deleted")}
            </a>
          </li>
        </ul>
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("menu.filter")} <b className="caret"></b>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#/">
                  Action
                </a>
                <a className="dropdown-item" href="#/">
                  Another action
                </a>
                <a className="dropdown-item" href="#/">
                  Something else here
                </a>
                <div role="separator" className="dropdown-divider"></div>
                <a className="dropdown-item" href="#/">
                  Separated link
                </a>
              </div>
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control ps-35px bg-light"
                    placeholder={t("menu.search") + "..."}
                    onChange={handleSearch}
                    value={search}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nr.</th>
                    <th>Name</th>
                    <th>Addresse</th>
                    <th>Kontakt</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <Customer key={customer.id} customer={customer} />
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                {limit * page} {t("menu.to")}{" "}
                {Math.min(limit * (page + 1), total)} {t("menu.from")} {total}{" "}
                {t("customers.customers")}
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li className={`page-item ${page === 0 && " active"}`}>
                  <Link
                    className={`page-link `}
                    to={`/customers?page=0&q=${search}`}
                  >
                    1
                  </Link>
                </li>
                {pages > 8 && (
                  <li className={`page-item ${page < 1 && "disabled"}`}>
                    <Link
                      className={`page-link `}
                      to={`/customers?page=${page - 1}&q=${search}`}
                    >
                      {"<"}
                    </Link>
                  </li>
                )}
                {Array(pages)
                  .fill(0)
                  .map((_, i) => (
                    <li
                      key={`page-${i}`}
                      className={`page-item ${page === i && " active"}`}
                    >
                      <Link
                        className="page-link"
                        to={`/customers?page=${i}&q=${search}`}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))
                  .slice(
                    Math.max(Math.min(page - 4, pages - 8), 1),
                    Math.min(Math.max(8, page + 5), pages - 1),
                  )}
                {pages > 8 && (
                  <li
                    className={`page-item ${
                      page + 1 > pages - 1 && "disabled"
                    }`}
                  >
                    <Link
                      className={`page-link `}
                      to={`/customers?page=${page + 1}&q=${search}`}
                    >
                      {">"}
                    </Link>
                  </li>
                )}

                {pages > 1 && (
                  <li className={`page-item`}>
                    <Link
                      className={`page-link  ${
                        page === pages - 1 && " active"
                      }`}
                      to={`/customers?page=${pages - 1}&q=${search}`}
                    >
                      {pages}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
