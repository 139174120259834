import React, { useContext } from "react";
import App from "./../app.jsx";

import Login from "../pages/auth/Login";
import { ProtectedRoute } from "./auth";
import Items from "../pages/items/Items";
import Customers from "../pages/customers/Customers";
import CustomerProperties from "../pages/customers/CustomerProperties";
import DeliveryNotes from "../pages/delivery_notes/DeliveryNotes";
import DeliveryNoteAddEdit from "../pages/delivery_notes/DeliveryNoteAddEdit";
import { Navigate } from "react-router-dom";
import Logout from "../pages/auth/Logout";
import DeliveryNoteDetails from "../pages/delivery_notes/DeliveryNoteDetails";
import Print from "../print";
import CustomerPropertyAddEdit from "../pages/customers/CustomerPropertyAddEdit";
import CustomerAddEdit from "../pages/customers/CustomerAddEdit";
import ItemAddEdit from "../pages/items/ItemAddEdit";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";

const AppRoute = [
  {
    path: "/print",
    element: (
      <ProtectedRoute>
        <Print />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "delivery_notes/:noteId/details",
        element: <DeliveryNoteDetails />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="delivery_notes" replace />,
      },
      {
        path: "delivery_notes",
        element: <DeliveryNotes />,
      },
      {
        path: "delivery_notes/add",
        element: <DeliveryNoteAddEdit />,
      },
      {
        path: "delivery_notes/:noteId/edit",
        element: <DeliveryNoteAddEdit />,
      },
      {
        path: "delivery_notes/:noteId/details",
        element: <DeliveryNoteDetails />,
      },
      {
        path: "items",
        element: <Items />,
      },
      {
        path: "items/add",
        element: <ItemAddEdit />,
      },
      {
        path: "items/:itemId/edit",
        element: <ItemAddEdit />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "customer_properties",
        element: <CustomerProperties />,
      },
      {
        path: "customers/add",
        element: <CustomerAddEdit />,
      },
      {
        path: "customers/:customerId/edit",
        element: <CustomerAddEdit />,
      },
      {
        path: "customer_properties/add",
        element: <CustomerPropertyAddEdit />,
      },
      {
        path: "customer_properties/:propertyId/edit",
        element: <CustomerPropertyAddEdit />,
      },

      {
        path: "auth/logout",
        element: <Logout />,
      },
    ],
  },

  {
    path: "/auth",
    element: <App />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot",
        element: <ForgotPassword />,
      },
      {
        path: "reset",
        element: <ResetPassword />,
      },
    ],
  },
];

export default AppRoute;
