import React, { createContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../stores/auth";
import { shallow } from "zustand/shallow";
import Spinner from "../components/misc/Spinner";

export const AuthContext = createContext();

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const [me, isLoading] = useAuthStore(
    (state) => [state.me, state.isLoading],
    shallow,
  );
  if (isLoading) {
    return <Spinner />;
  }
  return !!me ? (
    children
  ) : (
    <Navigate
      to={`/auth/login${
        location.pathname.includes("auth")
          ? ""
          : "?next=" + encodeURIComponent(location.pathname)
      }`}
    />
  );
};

export const AuthProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [fetchMe] = useAuthStore((state) => [state.fetchMe], shallow);

  const removeToken = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  useEffect(() => {
    fetchMe(token)
      .then(() => localStorage.setItem("token", token))
      .catch(() => localStorage.removeItem("token"));
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, setToken, removeToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};
