import request from "superagent";

for (let k of ["get", "post", "put", "patch", "delete", "head"]) {
  let f = request[k];
  request[k] = function () {
    return f
      .apply(this, arguments)
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`)
      .accept("json");
  };
}

export default request;
