import request from "./client";

export const getById = async ({ id }) => {
  const resp = await request.get(`/v1/delivery_notes/${id}`);
  return { data: resp.body };
};
export const getSome = async ({
  q = "",
  filter = "",
  limit = 10,
  offset = 0,
}) => {
  try {
    const resp = await request.get(
      `/v1/delivery_notes?limit=${limit}&offset=${offset}&q=${q}&filter=${filter}`,
    );
    const body = resp.body;
    return { total: body.meta.total, data: body.results, error: null };
  } catch (e) {
    if (!e.response) {
      return { total: 0, data: [], error: e };
    }
    return { total: 0, data: [], error: e.response.body };
  }
};

export const createOne = async ({
  state,
  submission,
  customer,
  customer_property,
  items,
  uploads,
  remark,
  customer_signature,
  customer_signature_name,
  employee_signature,
  employee_signature_name,
}) => {
  try {
    const resp = await request.post(`/v1/delivery_notes`).send({
      state: state,
      submission: submission,
      customer: customer,
      customer_property: customer_property,
      items: items,
      uploads: uploads,
      remark: remark,
      customer_signature: customer_signature,
      customer_signature_name: customer_signature_name,
      employee_signature: employee_signature,
      employee_signature_name: employee_signature_name,
    });
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response };
  }
};

export const updateOne = async ({
  id,
  submission,
  customer,
  customer_property,
  items,
  uploads,
  remark,
}) => {
  try {
    const resp = await request.put(`/v1/delivery_notes/${id}`).send({
      submission,
      customer,
      customer_property,
      items,
      uploads,
      remark,
    });
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response };
  }
};

export const changeState = async ({ id, state, attrs }) => {
  try {
    const resp = await request
      .post(`/v1/delivery_notes/${id}/state_transitions`)
      .send({
        state,
        attrs,
      });
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response };
  }
};
