import { create } from "zustand";
import produce from "immer";
import * as api from "../services/customers";
import { arrayToByIdsAllIds } from "./utils";

export const useCustomerStore = create((set) => ({
  byIds: {},
  allIds: [],
  total: 0,
  fetchSome: async ({ q = "", filter = "", limit = 20, offset = 0 }) => {
    const some = await api.getSome({ q, filter, limit, offset });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds(some.data);
        draft.total = some.total;

        // TODO make pagination work if we keep all data
        //[draft.byIds, draft.allIds] = arrayToByIdsAllIds([...Object.values(draft.byIds), ...some.data]);
        //draft.total = some.total;
      }),
    );
  },

  getById: async ({ id }) => {
    const one = await api.getById({ id });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
          ...Object.values(draft.byIds),
          one.data,
        ]);
      }),
    );
  },

  updateOne: async ({ id, name, address, contact }) => {
    const one = await api.updateOne({
      id,
      name,
      address,
      contact,
    });
    if (!!one.data) {
      set(
        produce((draft) => {
          [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
            ...Object.values(draft.byIds),
            one.data,
          ]);
        }),
      );
    }
    return one;
  },
}));
