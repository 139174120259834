import { Role } from "../stores/auth";

const Menu = (t) => {
  return [
    {
      path: "/delivery_notes",
      icon: "fa fa-file-lines",
      title: t("menu.delivery_notes"),
      roles: [Role.ADMINISTRATOR, Role.MANAGER, Role.TECHNICIAN],
    },
    {
      path: "/items",
      icon: "fa fa-gear",
      title: t("menu.items"),
      roles: [Role.ADMINISTRATOR, Role.MANAGER],
    },
    {
      path: "/customers",
      icon: "fa fa-address-card",
      title: t("menu.customers"),
      roles: [Role.ADMINISTRATOR, Role.MANAGER],
    },
    {
      path: "/customer_properties",
      icon: "fa fa-city",
      title: t("menu.customer_properties"),
      roles: [Role.ADMINISTRATOR, Role.MANAGER],
    },
    /*
  { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    children: [
      { path: '/menu/menu-1-1', title: 'Menu 1.1',
        children: [
          { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
            children: [
              { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
              { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
            ]
          },
          { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
          { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
        ]
      },
      { path: '/menu/menu-1-2', title: 'Menu 1.2' },
      { path: '/menu/menu-1-3', title: 'Menu 1.3' },
    ]
  }
  */
  ];
};

export default Menu;
