import React, { createContext, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useSettingsStore } from "../stores/settings";
import Spinner from "../components/misc/Spinner";
import { useTranslation } from "react-i18next";

export const SettingsContext = createContext();

export const SettingsProvider = (props) => {
  const { t, i18n } = useTranslation();
  const [
    env,
    whitelabel,
    translation,
    fetchWhitelabeling,
    fetchTranslation,
    fetchEnv,
  ] = useSettingsStore(
    (state) => [
      state.env,
      state.whitelabel,
      state.translation,
      state.fetchWhitelabeling,
      state.fetchTranslation,
      state.fetchEnv,
    ],
    shallow,
  );

  useEffect(() => {
    fetchWhitelabeling();
  }, []);

  useEffect(() => {
    fetchTranslation();
  }, []);

  useEffect(() => {
    fetchEnv();
  }, []);

  if (whitelabel === null || !translation === null || env === null) {
    return <Spinner />;
  }

  // add custom fetched tranlation file.
  // TODO make lang querable
  i18n.addResourceBundle("de", "custom", translation);

  return (
    <SettingsContext.Provider value={{ whitelabel, env }}>
      {props.children}
    </SettingsContext.Provider>
  );
};
