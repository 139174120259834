export const getRandomArbitrary = (min, max) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

export const bytesToSize = (bytes) => {
  const sizes = ["By", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 By";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  const value = Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  return value;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Collator

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});
export const sortAlphaNum = (a, b) => {
  //return a > b;
  return collator.compare(a, b);
};

export const arrayToByIds = (array, key = "id") =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const arrayToAllIds = (array) =>
  array.reduce(
    (arr, item) => (arr.includes(item.id) ? arr : [...arr, item.id]),
    [],
  );

// we use database for sorting!!!
//.sort((a, b) => a - b)
//.reverse();

export const arrayToByIdsAllIds = (array) => [
  arrayToByIds(array),
  arrayToAllIds(array),
];
