import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import DropdownNotification from "./dropdown/notification.jsx";
import DropdownLanguage from "./dropdown/language.jsx";
import DropdownProfile from "./dropdown/profile.jsx";
import SearchForm from "./search/form.jsx";
import DropdownMegaMenu from "./dropdown/mega.jsx";

import { AppSettings } from "./../../config/app-settings.js";
import { SettingsContext } from "../../config/settings";

export default function Header() {
  const { whitelabel, env } = useContext(SettingsContext);
  return (
    <AppSettings.Consumer>
      {({
        toggleAppSidebarMobile,
        toggleAppSidebarEnd,
        toggleAppSidebarEndMobile,
        toggleAppTopMenuMobile,
        appHeaderLanguageBar,
        appHeaderMegaMenu,
        appHeaderInverse,
        appSidebarTwo,
        appTopMenu,
        appSidebarNone,
      }) => (
        <Fragment>
          <div
            id="header"
            className={
              "app-header " + (appHeaderInverse ? "app-header-inverse" : "")
            }
          >
            <div
              className={`navbar-header  ${
                env !== "PRODUCTION" ? "bg-warning" : ""
              }`}
            >
              {appSidebarTwo && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarEndMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              <Link to="/" className="navbar-brand">
                <span className="navbar-logo"></span> {whitelabel.name}
              </Link>

              {appHeaderMegaMenu && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  data-bs-toggle="collapse"
                  data-bs-target="#top-navbar"
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x"></i>
                    <i className="fa fa-cog fa-stack-1x"></i>
                  </span>
                </button>
              )}
              {appTopMenu && !appSidebarNone && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppTopMenuMobile}
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x"></i>
                    <i className="fa fa-cog fa-stack-1x"></i>
                  </span>
                </button>
              )}
              {appSidebarNone && appTopMenu && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppTopMenuMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
              {!appSidebarNone && (
                <button
                  type="button"
                  className="navbar-mobile-toggler"
                  onClick={toggleAppSidebarMobile}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              )}
            </div>

            {appHeaderMegaMenu && <DropdownMegaMenu />}

            <div
              className={`navbar-nav ${
                env !== "PRODUCTION" ? "bg-warning" : ""
              }`}
            >
              {env !== "PRODUCTION" && (
                <h1 className="mx-auto">
                  <span className="text-capitalize">{env.toLowerCase()}</span>{" "}
                  Version
                </h1>
              )}
              {false && <SearchForm />}
              {false && <DropdownNotification />}

              {appHeaderLanguageBar && <DropdownLanguage />}

              <DropdownProfile />

              {appSidebarTwo && (
                <div className="navbar-divider d-none d-md-block"></div>
              )}

              {appSidebarTwo && (
                <div className="navbar-item d-none d-md-block">
                  <Link
                    to="/"
                    onClick={toggleAppSidebarEnd}
                    className="navbar-link icon"
                  >
                    <i className="fa fa-th"></i>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </AppSettings.Consumer>
  );
}
