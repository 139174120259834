import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import React, { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import Input from "../../components/form/Input";
import { Role, useAuthStore } from "../../stores/auth";
import * as Yup from "yup";
import { DEV_MODE } from "../../constants";
import { useCustomerStore } from "../../stores/customers";

const createInitialValues = (me, customer, isAddMode) => ({
  customer: {
    id: customer?.id || "",
    number: customer?.number || "",
    name: customer?.name || "",
    address: {
      street: customer?.addresses[0]?.street || "",
      zip: customer?.addresses[0]?.zip || "",
      city: customer?.addresses[0]?.city || "",
      district: customer?.addresses[0]?.district || "",
    },
    contact: {
      name: customer?.contacts[0]?.name || "",
    },
  },
});

const createValidationSchema = (t) => Yup.object().shape({});

function CustomerAddEdit() {
  const { t } = useTranslation();
  const params = useParams();
  const customerId = Number.parseInt(params.customerId);
  const isAddMode = !customerId;
  const navigate = useNavigate();

  const [me, hasRole] = useAuthStore(
    (state) => [state.me, state.hasRole],
    shallow,
  );
  const canView = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canEdit = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);

  const [byIds, allIds, getById, updateOne] = useCustomerStore(
    (state) => [state.byIds, state.allIds, state.getById, state.updateOne],
    shallow,
  );

  const customer = Number.isInteger(customerId) ? byIds[customerId] : null;

  useEffect(() => {
    if (!!customerId) {
      getById({ id: customerId });
    }
  }, [customerId]);
  const handleSubmit = async (values, { setErrors }) => {
    console.log(values);
    const func = isAddMode ? alert("Not impl.") : updateOne;
    const { error } = await func({
      id: customerId,
      name: values.customer.name,
      address: values.customer.address,
      contact: values.customer.contact,
    });

    if (!!error) {
      //error.detail.forEach(d => setErrors)
      alert(JSON.stringify(error.text));
      //console.log(error)
    } else {
      navigate(`/customers`);
    }
  };

  if (Number.isInteger(customerId) && !customer) {
    return <Spinner />;
  }

  if (!canView) {
    navigate("/");
  }

  const canSubmit = canEdit;

  return (
    <div>
      <div className="d-flex align-items-center mb-3 mx-3 mx-md-0">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/customer_properties">{t("customers.customers")}</Link>
            </li>
            <li className="breadcrumb-item active">
              <i className="fa fa-arrow-back"></i>
              {isAddMode ? (
                t("menu.add")
              ) : (
                <span>
                  {t("common.number_short")} {customer.number}
                </span>
              )}
            </li>
          </ol>
          <h1 className="page-header mb-0">{t("customers.customers")}</h1>
        </div>
      </div>

      {!canSubmit && (
        <div className="alert alert-danger">
          {t("permissions.can_not_edit")}
        </div>
      )}

      <Formik
        initialValues={createInitialValues(me, customer, isAddMode)}
        //validationSchema={createValidationSchema(t)}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <div className="row">
              <div className="col">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-address-card fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("customers.customer")}
                    {!!customer?.import_id && (
                      <span className="float-end">
                        Import ID: {customer.import_id}
                      </span>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row mb-3 gx-3">
                      <div className="col-9">
                        <label className="form-label" htmlFor="customer.name">
                          {t("customers.name")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.name"
                          className="form-control fs-13px h-45px"
                        />
                        <ErrorMessage
                          name="customer.name"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col-3">
                        <label className="form-label" htmlFor="customer.number">
                          {t("customers.number")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.number"
                          className={`form-control fs-13px h-45px ${
                            getIn(errors, "customer.number") ? "is-invalid" : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          name="customer.number"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 gx-3">
                      <div className="col-12">
                        <label
                          className="form-label"
                          htmlFor="customer.address.street"
                        >
                          {t("address.street")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.address.street"
                          className="form-control fs-13px h-45px"
                        />
                        <ErrorMessage
                          name="customer.address.street"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 gx-3">
                      <div className="col-4">
                        <label
                          className="form-label"
                          htmlFor="customer.address.zip"
                        >
                          {t("address.zip")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.address.zip"
                          className="form-control fs-13px h-45px"
                        />
                        <ErrorMessage
                          name="customer.address.zip"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col-8">
                        <label
                          className="form-label"
                          htmlFor="customer.address.city"
                        >
                          {t("address.city")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.address.city"
                          className="form-control fs-13px h-45px"
                        />
                        <ErrorMessage
                          name="customer.address.city"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gx-3">
                      <div className="col-12">
                        <label
                          className="form-label"
                          htmlFor="customer.address.district"
                        >
                          {t("address.district")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.address.district"
                          className="form-control fs-13px h-45px"
                        />
                        <ErrorMessage
                          name="customer.address.district"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3"></div>
                  <div className="card-body">
                    <div className="btn btn-group d-flex">
                      <button
                        className="btn btn-success w-100"
                        disabled={isSubmitting || !canSubmit}
                      >
                        {t("menu.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-city fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("customers.properties.properties")}
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled">
                      {!!customer.properties &&
                        customer.properties.map((property) => (
                          <li
                            key={property.id}
                            className="border-bottom border-1"
                          >
                            <address className="mt-5px">
                              <Link
                                to={`/customer_properties/${property.id}/edit`}
                              >
                                {property.number}
                              </Link>
                              <br />
                              {property.name}
                              <br />
                              <span className="text-nowrap">
                                {property.addresses[0].street}
                              </span>
                              <br />
                              {property.addresses[0].zip}{" "}
                              {property.addresses[0].city}
                              <br />
                            </address>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {DEV_MODE && (
              <pre
                className="m-t-xl"
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CustomerAddEdit;
