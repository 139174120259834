import React, { useContext } from "react";
import {
  useResolvedPath,
  useMatch,
  NavLink,
  useLocation,
  matchPath,
} from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { AppSettings } from "./../../config/app-settings.js";
import Menu from "./../../config/app-menu.jsx";
import { useAuthStore } from "../../stores/auth";
import { shallow } from "zustand/shallow";

function NavItem({ menu, ...props }) {
  let resolved = useResolvedPath(menu.path);
  let match = useMatch({ path: resolved.pathname });

  let location = useLocation();
  let match2 = matchPath({ path: menu.path, end: false }, location.pathname);

  let icon = menu.icon && (
    <div className="menu-icon">
      <i className={menu.icon}></i>
    </div>
  );
  let img = menu.img && (
    <div className="menu-icon-img">
      <img src={menu.img} alt="" />
    </div>
  );
  let caret = menu.children && !menu.badge && (
    <div className="menu-caret"></div>
  );
  let label = menu.label && (
    <span className="menu-label ms-5px">{menu.label}</span>
  );
  let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
  let highlight = menu.highlight && (
    <i className="fa fa-paper-plane text-theme"></i>
  );
  let title = menu.title && (
    <div className="menu-text">
      {menu.title} {label} {highlight}
    </div>
  );

  return (
    <div
      className={
        "menu-item" +
        (match || match2 ? " active" : "") +
        (menu.children ? " has-sub" : "")
      }
    >
      <NavLink className="menu-link" to={menu.path} {...props}>
        {img} {icon} {title}
        {caret} {badge}
      </NavLink>

      {menu.children && (
        <div className="menu-submenu">
          {menu.children.map((submenu, i) => (
            <NavItem key={i} menu={submenu} />
          ))}
        </div>
      )}
    </div>
  );
}

function SidebarNav() {
  const { t } = useTranslation();
  const [hasRole] = useAuthStore((state) => [state.hasRole], shallow);
  const menus = Menu(t);
  return (
    <div className="menu">
      <div className="menu-header">Navigation</div>
      {menus
        .filter((m) => m.roles.some(hasRole))
        .map((menu, i) => (
          <NavItem key={i} menu={menu} />
        ))}
    </div>
  );
}

export default SidebarNav;
