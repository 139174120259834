import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { shallow } from "zustand/shallow";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useCustomerPropertiesStore } from "../../stores/customer_properties";
import {
  DELIVERY_NOTE_STATE,
  useDeliveryNotesStore,
} from "../../stores/delivery_notes";
import { DEV_MODE } from "../../constants";
import AsyncSelectWrapper from "../../components/select/AsyncSelectWrapper";
import Input from "../../components/form/Input";
import Spinner from "../../components/misc/Spinner";
import { useDeliveryNoteSubmissionsStore } from "../../stores/delivery_note_submissions";
import SignatureCanvas from "react-signature-canvas";

import "../../scss/signature-pad.scss";
import { useAuthStore, Role } from "../../stores/auth";
import { ITEM_CHARACTERISTIC } from "../../stores/items";
import TusJsClientWrapper from "../../components/uploads/TusJsClientWrapper";

const createInitialValues = (me, submission, deliveryNote, isAddMode) => {
  // we always need the origin ids or ""
  const customerId = deliveryNote?.customer?.origin?.id || "";
  const customerPropertyId = deliveryNote?.customer_property?.origin?.id || "";

  // if we are in add mode we use latest values from origins otherwise in edit mode we use original values from delivery note
  const customer = isAddMode
    ? deliveryNote?.customer?.origin
    : deliveryNote?.customer;
  const customerProperty = isAddMode
    ? deliveryNote?.customer_property?.origin
    : deliveryNote?.customer_property;

  return {
    state: DELIVERY_NOTE_STATE.DELIVERED,
    customer_signature: "",
    customer_signature_name: "",
    employee_signature: me.signature || "",
    employee_signature_name: me.name || "",
    customer: {
      id: customerId,
      number: customer?.number || "",
      name: customer?.name || "",
      address: {
        street: customer?.addresses[0]?.street || "",
        zip: customer?.addresses[0]?.zip || "",
        city: customer?.addresses[0]?.city || "",
        district: customer?.addresses[0]?.district || "",
      },
      contact: {
        name: customer?.contacts[0]?.name || "",
      },
    },
    customer_property: {
      id: customerPropertyId,
      number: customerProperty?.number || "",
      name: customerProperty?.name || "",
      address: {
        street: customerProperty?.addresses[0]?.street || "",
        zip: customerProperty?.addresses[0]?.zip || "",
        city: customerProperty?.addresses[0]?.city || "",
        district: customerProperty?.addresses[0]?.district || "",
      },
      contact: {
        name: customerProperty?.contacts[0]?.name || "",
      },
    },

    item_groups: Array.from(
      submission.items
        .reduce((map, item) => {
          if (map.has(item.groups[0].id)) {
            map.get(item.groups[0].id)["items"].push(item);
          } else {
            map.set(item.groups[0].id, { ...item.groups[0], items: [item] });
          }
          return map;
        }, new Map())
        .values(),
    ).map((submissionGroup) => ({
      ...submissionGroup,
      items: submissionGroup.items.flatMap((submissionItem) => {
        let visibleItems = [];
        if (!!deliveryNote) {
          // if there are items of this submission type use them
          visibleItems.push(
            ...deliveryNote.items
              .filter(
                (deliveryNoteItem) =>
                  deliveryNoteItem.number === submissionItem.number,
              )
              .map((deliveryNoteItem, index) => ({
                ...submissionItem,
                attributes: [...deliveryNoteItem.attributes],
                amount: deliveryNoteItem.amount,
                existedBefore: true, // highlight bg if this is a previous existing value. don't use highlight because of conflict with db model
                isAdditionalItem: index > 0, // mark items after first one of same number as additional
              })),
          );
        }

        // if there is no submissionItem of this type add default one
        if (visibleItems.length === 0) {
          visibleItems.push(submissionItem);
        }

        return visibleItems.map((item) => ({
          ...item,
          attributes: item.attributes.map((attr, index) => {
            return {
              ...attr,
              isNewChoice: false,
            };
          }),
          amount: item.amount || "",
        }));
      }),
    })),
    remark: deliveryNote?.remark || "",
    uploads: [],
  };
};

const createValidationSchema = (t) =>
  Yup.object().shape({
    state: Yup.string().oneOf(Object.values(DELIVERY_NOTE_STATE)),
    customer: Yup.object().shape({
      id: Yup.number().required(),
      number: Yup.string().required(
        t("form.errors.required", { attr: t("customers.number") }),
      ),
    }),
    customer_property: Yup.object().shape({
      id: Yup.number().required(),
      number: Yup.string().required(
        t("form.errors.required", { attr: t("customers.properties.number") }),
      ),
      //name: Yup.string().required(),
    }),
    item_groups: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        description: Yup.string(),

        items: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            number: Yup.string(),
            name: Yup.string(),
            description: Yup.string(),
            amount: Yup.number()
              .nullable()
              .typeError(t("errors.types.number.number")),
          }),
        ),
      }),
    ),
    uploads: Yup.array().of(
      Yup.object().shape({
        url: Yup.string().required(),
        hash: Yup.string().required(),
        name: Yup.string().required(),
        size: Yup.number().required(),
        type: Yup.string().required(),
      }),
    ),
  });

function DeliveryNoteAddEdit() {
  const { t } = useTranslation();
  const params = useParams();
  const noteId = Number.parseInt(params.noteId);
  const isAddMode = !noteId;
  const navigate = useNavigate();
  const customerSignatureRef = useRef();
  const employeeSignatureRef = useRef();

  const [showCustomerSignatureField, setShowCustomerSignatureField] =
    useState(false);
  const [lockCustomerSignatureField, setLockCustomerSignatureField] =
    useState(false);
  const [showEmployeeSignatureField, setShowEmployeeSignatureField] =
    useState(false);
  const [lockEmployeeSignatureField, setLockEmployeeSignatureField] =
    useState(false);

  const [queryParams, setQueryhParams] = useSearchParams();

  const [me, hasRole] = useAuthStore(
    (state) => [state.me, state.hasRole],
    shallow,
  );
  const canEdit = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);

  const deliveryNoteId = isAddMode
    ? parseInt(queryParams.get("copyFromId")) || null
    : noteId;

  const [
    deliveryNotesByIds,
    createOneDeliveryNote,
    updateOneDeliveryNote,
    getDeliveryNoteById,
  ] = useDeliveryNotesStore(
    (state) => [state.byIds, state.createOne, state.updateOne, state.getById],
    shallow,
  );

  const deliveryNote = Number.isInteger(deliveryNoteId)
    ? deliveryNotesByIds[deliveryNoteId]
    : null;

  const submissionId = isAddMode
    ? parseInt(queryParams.get("submissionId")) || null
    : deliveryNote?.submission.id || null;

  const [submissionByIds, getSubmissionById] = useDeliveryNoteSubmissionsStore(
    (state) => [state.byIds, state.getById],
    shallow,
  );

  const submission = Number.isInteger(submissionId)
    ? submissionByIds[submissionId]
    : null;

  const [fetchSomeCustomerPropertiesByAddress] = useCustomerPropertiesStore(
    (state) => [state.fetchSomeByAddress],
    shallow,
  );

  useEffect(() => {
    if (!!submissionId) {
      getSubmissionById({ id: submissionId });
    }
  }, [submissionId]);

  useEffect(() => {
    if (!!deliveryNoteId) {
      getDeliveryNoteById({ id: deliveryNoteId });
    }
  }, [deliveryNoteId]);

  //useEffect(() =>{
  //  if(!!employeeSignatureRef.current && !!me.signature) {
  //    employeeSignatureRef.current.fromDataURL(me.signature)
  //  }
  //
  //}, [employeeSignatureRef.current])

  const fetchByStreet = async (inputValue) => {
    const { data } = await fetchSomeCustomerPropertiesByAddress({
      q: inputValue,
      limit: 20,
    });
    return data.map((p) => ({
      value: p,
      label: `${!!p.name ? p.name + ", " : ""}${
        !!p.addresses[0].street ? p.addresses[0].street + ", " : ""
      }${!!p.addresses[0].zip ? p.addresses[0].zip + " " : ""}${
        !!p.addresses[0].city ? p.addresses[0].city : ""
      }`,
    }));
  };

  const handleSubmit = async (values, { setErrors }) => {
    const items = values.item_groups
      .map((g) => g.items)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((i) => i.amount !== ""); // concat items
    const func = isAddMode ? createOneDeliveryNote : updateOneDeliveryNote;
    const { error } = await func({
      id: noteId,
      state: values.state,
      submission: submission,
      customer: values.customer,
      customer_property: values.customer_property,
      items: items.map((item) => ({
        origin_id: item.id,
        amount: item.amount,
        attributes: item.attributes.map((attr) => ({
          ...attr,
          origin_id: attr.id,
        })),
      })),
      uploads: values.uploads,
      remark: values.remark,
      customer_signature:
        isAddMode &&
        !!customerSignatureRef.current &&
        !customerSignatureRef.current.isEmpty()
          ? customerSignatureRef.current
              .getTrimmedCanvas()
              .toDataURL("image/png")
          : null,
      customer_signature_name:
        isAddMode &&
        !!customerSignatureRef.current &&
        !customerSignatureRef.current.isEmpty()
          ? values.customer_signature_name
          : null,
      employee_signature:
        isAddMode &&
        !!employeeSignatureRef.current &&
        !employeeSignatureRef.current.isEmpty()
          ? employeeSignatureRef.current
              .getTrimmedCanvas()
              .toDataURL("image/png")
          : values.employee_signature,
      employee_signature_name:
        isAddMode &&
        !!employeeSignatureRef.current &&
        !employeeSignatureRef.current.isEmpty()
          ? values.employee_signature_name
          : null,
    });

    if (!!error) {
      //error.detail.forEach(d => setErrors)
      alert(JSON.stringify(error.text, null, 2));
    } else {
      isAddMode
        ? navigate(`/delivery_notes`)
        : navigate(`/delivery_notes/${noteId}/details`);
    }
  };

  if (!submission) {
    return <Spinner />;
  }

  if (Number.isInteger(deliveryNoteId) && !deliveryNote) {
    return <Spinner />;
  }

  const isReviewed = !isAddMode && deliveryNote.is_reviewed;
  const canSubmit = isAddMode || (canEdit && !isReviewed);

  return (
    <div>
      <div className="d-flex align-items-center mb-3 mx-3 mx-md-0">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/delivery_notes">
                {t("delivery_notes.delivery_notes")}
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <i className="fa fa-arrow-back"></i>
              {isAddMode ? (
                t("menu.add")
              ) : (
                <span>
                  {t("common.number_short")} {deliveryNote.number}
                </span>
              )}
            </li>
          </ol>
          <h1 className="page-header mb-0">
            {t("delivery_notes.delivery_note")} {submission.name}
          </h1>
        </div>
      </div>

      {isReviewed && (
        <div className="alert alert-danger">
          {t("delivery_notes.alerts.reviewed")}
        </div>
      )}
      {!canSubmit && (
        <div className="alert alert-danger">
          {t("permissions.can_not_edit")}
        </div>
      )}

      <Formik
        initialValues={createInitialValues(
          me,
          submission,
          deliveryNote,
          isAddMode,
        )}
        validationSchema={createValidationSchema(t)}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <div className="row">
              <div className="col">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-sitemap fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("customers.customer")} /{" "}
                    {t("customers.properties.property")}
                  </div>
                  <div className="card-body">
                    {isAddMode && (
                      <Fragment>
                        <div className="row mb-3 gx-3">
                          <div className="col-12">
                            <AsyncSelectWrapper
                              //cacheOptions
                              loadOptions={fetchByStreet}
                              placeholder={t("customers.properties.search")}
                              onChange={async ({ value }) => {
                                await setFieldValue(
                                  "customer.id",
                                  !!value ? value.customers[0].id : null,
                                );
                                await setFieldValue(
                                  "customer.number",
                                  !!value ? value.customers[0].number : "",
                                );
                                await setFieldValue(
                                  "customer.name",
                                  !!value ? value.customers[0].name : "",
                                );
                                await setFieldValue(
                                  "customer_property.id",
                                  !!value ? value.id : null,
                                );
                                await setFieldValue(
                                  "customer_property.name",
                                  !!value ? value.name : "",
                                );
                                await setFieldValue(
                                  "customer_property.address.street",
                                  !!value ? value.addresses[0].street : "",
                                );
                                await setFieldValue(
                                  "customer_property.address.zip",
                                  !!value ? value.addresses[0].zip : "",
                                );
                                await setFieldValue(
                                  "customer_property.address.city",
                                  !!value ? value.addresses[0].city : "",
                                );
                                await setFieldValue(
                                  "customer_property.address.district",
                                  !!value ? value.addresses[0].district : "",
                                );
                                await setFieldValue(
                                  "customer_property.number",
                                  !!value ? value.number : "",
                                );
                              }}
                              // remove arrow
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    )}
                    <div className="row mb-3 gx-3">
                      <div className="col-8 col-md-9">
                        <label className="form-label" htmlFor="customer.name">
                          {t("customers.name")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.name"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer.name"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col-4 col-md-3">
                        <label className="form-label" htmlFor="customer.number">
                          {t("customers.number")}
                        </label>
                        <Field
                          component={Input}
                          name="customer.number"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer.number"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 gx-3">
                      <div className="col-8 col-md-9">
                        <label
                          className="form-label"
                          htmlFor="customer_property.name"
                        >
                          {t("customers.properties.name")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.name"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.name"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col-4 col-md-3">
                        <label
                          className="form-label"
                          htmlFor="customer_property.number"
                        >
                          {t("customers.properties.number")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.number"
                          className={`form-control fs-13px h-45px ${
                            getIn(errors, "customer_property.number")
                              ? "is-invalid"
                              : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.number"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 gx-3">
                      <div className="col">
                        <label
                          className="form-label"
                          htmlFor="customer_property.address.street"
                        >
                          {t("customers.properties.street")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.address.street"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.address.street"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 gx-3">
                      <div className="col-5 col-md-4">
                        <label
                          className="form-label"
                          htmlFor="customer_property.address.zip"
                        >
                          {t("customers.properties.zip")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.address.zip"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.address.zip"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col col-md-8">
                        <label
                          className="form-label"
                          htmlFor="customer_property.address.city"
                        >
                          {t("customers.properties.city")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.address.city"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.address.city"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gx-3">
                      <div className="col">
                        <label
                          className="form-label"
                          htmlFor="customer_property.address.district"
                        >
                          {t("customers.properties.district")}
                        </label>
                        <Field
                          component={Input}
                          name="customer_property.address.district"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="customer_property.address.district"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>

                    {/*<div className="row mb-3 gx-3">
                      <div className="col">
                        <button type={"button"} className={"btn btn-red"}>
                          {t("customers.new")}
                        </button>
                      </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <FieldArray
                name="item_groups"
                render={(arrayHelpers) => (
                  <Fragment>
                    {values.item_groups.map((group, group_index) => (
                      <div key={`group-${group.id}`} className="col-12">
                        <div className="card border-0 mb-4">
                          <div className="card-header h6 mb-0 bg-none p-3">
                            <i className="fa fa-cog fa-lg fa-fw text-dark text-opacity-50 me-1" />{" "}
                            {group.name}
                          </div>
                          <FieldArray
                            name={`item_groups.${group_index}.items`}
                            render={(arrayHelpersItems) => (
                              <div className="card-body">
                                {group.items.map((item, item_index) => (
                                  <Fragment
                                    key={`item-${item.id}-${item_index}`}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-2 col-md-1 mb-2 mb-sm-0 text-nowrap">
                                        {item.number}
                                      </div>
                                      <div className="col col-md-6 mb-2 mb-sm-0">
                                        {item.name}{" "}
                                        {!!item.inspect_every_x_months && (
                                          <span className="badge bg-yellow-100 fs-10px rounded-pill text-dark">
                                            {item.inspect_every_x_months}{" "}
                                            {t("common.months")}
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-12 col-md">
                                        <div className="input-group">
                                          <Field
                                            name={`item_groups.${group_index}.items.${item_index}.amount`}
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            disabled={
                                              item.characteristic ===
                                              ITEM_CHARACTERISTIC.UNIQUE
                                            }
                                            className={`form-control ${
                                              item?.existedBefore &&
                                              "bg-yellow-100 bg-opacity-25"
                                            } ${
                                              getIn(
                                                errors,
                                                `item_groups.${group_index}.items.${item_index}.amount`,
                                              )
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            placeholder={t("items.amount")}
                                          />
                                          <ErrorMessage
                                            name={`item_groups.${group_index}.items.${item_index}.amount`}
                                            render={(error) => (
                                              <div className="invalid-feedback">
                                                {t(error)}
                                              </div>
                                            )}
                                          />
                                          {item.characteristic !==
                                            ITEM_CHARACTERISTIC.DIFFERENT && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                if (!item.amount) {
                                                  setFieldValue(
                                                    `item_groups.${group_index}.items.${item_index}.amount`,
                                                    1,
                                                  );
                                                } else {
                                                  arrayHelpersItems.insert(
                                                    item_index + 1,
                                                    {
                                                      ...item,
                                                      isAdditionalItem: true,
                                                    },
                                                  );
                                                }
                                              }}
                                              className="btn btn-success"
                                            >
                                              <i className="fa fa-plus" />{" "}
                                            </button>
                                          )}
                                          {item.characteristic !==
                                            ITEM_CHARACTERISTIC.DIFFERENT && (
                                            <button
                                              type="button"
                                              disabled={!item.amount}
                                              onClick={() => {
                                                if (!!item.isAdditionalItem) {
                                                  arrayHelpersItems.remove(
                                                    item_index,
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `item_groups.${group_index}.items.${item_index}.amount`,
                                                    "",
                                                  );
                                                }
                                              }}
                                              className={`btn btn-danger`}
                                            >
                                              <i className="fa fa-minus" />{" "}
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {!!item.amount &&
                                      item.attributes.map(
                                        (attr, attr_index) => (
                                          <div
                                            key={attr.id}
                                            className="row align-items-center mt-1"
                                          >
                                            <div className="col-1 d-none d-sm-flex"></div>
                                            <div className="col-12 col-md-6">
                                              {attr.name}
                                            </div>
                                            <div className="col">
                                              {attr.type !== "select" ||
                                              attr.isNewChoice ? (
                                                <div className="input-group">
                                                  <Field
                                                    name={`item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values[0]`}
                                                    placeholder={
                                                      attr.isNewChoice
                                                        ? t(
                                                            "items.attributes.menu.add_choice",
                                                          )
                                                        : ""
                                                    }
                                                    className={`form-control ${
                                                      item?.existedBefore &&
                                                      "bg-yellow-100 bg-opacity-25"
                                                    } ${
                                                      getIn(
                                                        errors,
                                                        `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values[0]`,
                                                      )
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                  />
                                                  {attr.type === "select" && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      placeholder={t(
                                                        "items.attributes.menu.select_choice",
                                                      )}
                                                      onClick={() => {
                                                        setFieldValue(
                                                          `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.isNewChoice`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values`,
                                                          values.item_groups[
                                                            group_index
                                                          ].items[item_index]
                                                            .attributes[
                                                            attr_index
                                                          ]._tmp.original
                                                            .values,
                                                        );
                                                      }}
                                                    >
                                                      <span className="fa fa-pencil"></span>
                                                    </button>
                                                  )}
                                                </div>
                                              ) : (
                                                <div className="input-group">
                                                  <Field
                                                    as={"select"}
                                                    name={`item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values[0]`}
                                                    className={`form-select ${
                                                      item?.existedBefore &&
                                                      "bg-yellow-100 bg-opacity-25"
                                                    } ${
                                                      getIn(
                                                        errors,
                                                        `item_groups.${group_index}.attributes.${attr_index}.data.values[0]`,
                                                      )
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    placeholder={t(
                                                      "items.amount",
                                                    )}
                                                  >
                                                    {attr.data?.choices.map(
                                                      (choice, i) => (
                                                        <option
                                                          key={choice}
                                                          value={choice}
                                                        >
                                                          {choice}
                                                        </option>
                                                      ),
                                                    )}
                                                  </Field>
                                                  <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                      //setSelectedAttributeId(attr.id)
                                                      setFieldValue(
                                                        `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.isNewChoice`,
                                                        true,
                                                      );
                                                      setFieldValue(
                                                        `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}._tmp.original.values`,
                                                        values.item_groups[
                                                          group_index
                                                        ].items[item_index]
                                                          .attributes[
                                                          attr_index
                                                        ].data.values,
                                                      );
                                                      setFieldValue(
                                                        `item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values[0]`,
                                                        "",
                                                      );
                                                    }}
                                                  >
                                                    <span className="fa fa-plus"></span>
                                                  </button>
                                                </div>
                                              )}

                                              <ErrorMessage
                                                name={`item_groups.${group_index}.items.${item_index}.attributes.${attr_index}.data.values[0]`}
                                                render={(error) => (
                                                  <div className="invalid-feedback">
                                                    {t(error)}
                                                  </div>
                                                )}
                                              />
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    <hr className="mt-2 mb-2" />
                                  </Fragment>
                                ))}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </Fragment>
                )}
              />
            </div>
            <div className="row">
              <div className="col">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-paperclip fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("menu.files")}
                  </div>
                  <div className="card-body">
                    <FieldArray
                      name={`uploads`}
                      render={(arrayHelperUploads) => (
                        <div className="row align-items-center">
                          <div className="col">
                            <TusJsClientWrapper
                              metadata={{ userId: me.id }}
                              accept="image/*"
                              onSuccess={(upload) =>
                                arrayHelperUploads.push(upload)
                              }
                            />
                          </div>
                        </div>
                      )}
                    />
                    {!isAddMode && (
                      <div className="row mt-3">
                        <div className="col">
                          <div className="flot-start">
                            {deliveryNote.files
                              .filter((file) => file.is_thumbnailable)
                              .map((file) => (
                                <a
                                  key={file.id}
                                  href={`/v1/files/${file.id}/content`}
                                >
                                  <img
                                    className="me-2 mb-2 border-end border-bottom rounded"
                                    style={{ width: "unset", height: "100px" }}
                                    src={`/v1/files/${file.id}/thumbnails?height=100`}
                                  />
                                </a>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-paperclip fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("menu.remark")}
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="form-control p-0 overflow-hidden">
                          <ReactQuill
                            value={values.remark}
                            onChange={(text) => setFieldValue("remark", text)}
                            className={`border-0 h-200px mb-40px ${
                              deliveryNote?.remark &&
                              "bg-yellow-100 bg-opacity-25"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isAddMode && (
                  <div className="card border-0 mb-4">
                    <div className="card-header h6 mb-0 bg-none p-3">
                      <div className="btn-group btn-group-justified w-100">
                        <button
                          type="button"
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          onClick={() =>
                            setShowEmployeeSignatureField((prev) => !prev)
                          }
                        >
                          <i className="fa fa-pencil fa-lg fa-fw text-dark text-opacity-50 me-1" />
                          {t("customers.menu.signature")} {t("common.expert")}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            !lockEmployeeSignatureField
                              ? employeeSignatureRef.current.off()
                              : employeeSignatureRef.current.on();
                            setLockEmployeeSignatureField((prev) => !prev);
                          }}
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          disabled={!showEmployeeSignatureField}
                        >
                          <i
                            className={`fa fa-${
                              lockEmployeeSignatureField ? "lock" : "unlock"
                            }`}
                          />{" "}
                          {t(
                            !lockEmployeeSignatureField
                              ? "common.lock"
                              : "common.unlock",
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => employeeSignatureRef.current.clear()}
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          disabled={
                            !showEmployeeSignatureField ||
                            lockEmployeeSignatureField
                          }
                        >
                          <i className="fa fa-refresh" /> {t("common.reset")}
                        </button>
                      </div>
                    </div>
                    {showEmployeeSignatureField && (
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={`col border-bottom border-2 ${
                              lockEmployeeSignatureField ? "bg-light" : ""
                            }`}
                          >
                            <SignatureCanvas
                              penColor="black"
                              clearOnResize={false}
                              canvasProps={{ className: "signature-pad" }}
                              ref={employeeSignatureRef}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col">
                            <label
                              className="form-label"
                              htmlFor="employee_signature_name"
                            >
                              {t("common.full_name")}
                            </label>
                            <Field
                              component={Input}
                              name="employee_signature_name"
                              className="form-control fs-13px h-45px"
                              disabled={true}
                            />
                            <ErrorMessage
                              name="employee_signature_name"
                              render={(msg) => (
                                <div className="invalid-feedback">{t(msg)}</div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!showEmployeeSignatureField && !!me.signature && (
                      <img src={me.signature} className="my-3 mx-auto" />
                    )}
                  </div>
                )}
                {isAddMode && (
                  <div className="card border-0 mb-4">
                    <div className="card-header h6 mb-0 bg-none p-3">
                      <div className="btn-group btn-group-justified w-100">
                        <button
                          type="button"
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          onClick={() =>
                            setShowCustomerSignatureField((prev) => !prev)
                          }
                        >
                          <i className="fa fa-pencil fa-lg fa-fw text-dark text-opacity-50 me-1" />{" "}
                          {t("customers.menu.signature")}{" "}
                          {t("customers.customer")}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            !lockCustomerSignatureField
                              ? customerSignatureRef.current.off()
                              : customerSignatureRef.current.on();
                            setLockCustomerSignatureField((prev) => !prev);
                          }}
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          disabled={!showCustomerSignatureField}
                        >
                          <i
                            className={`fa fa-${
                              lockCustomerSignatureField ? "lock" : "unlock"
                            }`}
                          />{" "}
                          {t(
                            !lockCustomerSignatureField
                              ? "common.lock"
                              : "common.unlock",
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => customerSignatureRef.current.clear()}
                          className="btn btn-default"
                          style={{ width: "33%" }}
                          disabled={
                            !showCustomerSignatureField ||
                            lockCustomerSignatureField
                          }
                        >
                          <i className="fa fa-refresh" /> {t("common.reset")}
                        </button>
                      </div>
                    </div>
                    {showCustomerSignatureField && (
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={`col border-bottom border-2 ${
                              lockCustomerSignatureField ? "bg-light" : ""
                            }`}
                          >
                            <SignatureCanvas
                              penColor="black"
                              clearOnResize={false}
                              canvasProps={{ className: "signature-pad" }}
                              ref={customerSignatureRef}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col">
                            <label
                              className="form-label"
                              htmlFor="customer_signature_name"
                            >
                              {t("common.full_name")}
                            </label>
                            <Field
                              component={Input}
                              name="customer_signature_name"
                              className="form-control fs-13px h-45px"
                            />
                            <ErrorMessage
                              name="customer_signature_name"
                              render={(msg) => (
                                <div className="invalid-feedback">{t(msg)}</div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3"></div>
                  <div className="card-body">
                    <div className="btn btn-group d-flex">
                      {/*<button
                        className="btn btn-warning w-100"
                        disabled={isSubmitting}
                      >
                        {t("delivery_notes.menu.save")}
                      </button>*/}
                      <button
                        //type="submit"
                        //onClick={() =>
                        //  setFieldValue("state", DELIVERY_NOTE_STATE.DELIVERED)
                        //}
                        className="btn btn-success w-100"
                        disabled={isSubmitting || !canSubmit}
                      >
                        {isAddMode
                          ? t("delivery_notes.menu.completed")
                          : t("delivery_notes.menu.save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {DEV_MODE && (
              <pre
                className="m-t-xl"
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DeliveryNoteAddEdit;
