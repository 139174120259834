import { create } from "zustand";
import produce from "immer";
import * as api from "../services/items";
import { arrayToByIdsAllIds } from "./utils";

export const ITEM_CHARACTERISTIC = Object.freeze({
  DIFFERENT: "DIFFERENT",
  SIMULAR: "SIMULAR",
  UNIQUE: "UNIQUE",
});

export const useItemStore = create((set) => ({
  byIds: {},
  allIds: [],
  total: 0,
  getById: async ({ id }) => {
    const one = await api.getById({ id });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
          ...Object.values(draft.byIds),
          one.data,
        ]);
      }),
    );
  },
  fetchSome: async ({ q = "", filter = "", limit = 20, offset = 0 }) => {
    const some = await api.getSome({ q, filter, limit, offset });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds(some.data);
        draft.total = some.total;

        // TODO make pagination work if we keep all data
        //[draft.byIds, draft.allIds] = arrayToByIdsAllIds([...Object.values(draft.byIds), ...some.data]);
        //draft.total = some.total;
      }),
    );
  },

  updateOne: async ({
    id,
    number,
    name,
    description,
    highlight,
    characteristic,
    inspect_every_x_months,
  }) => {
    const one = await api.updateOne({
      id,
      number,
      name,
      description,
      highlight,
      characteristic,
      inspect_every_x_months,
    });
    if (!!one.data) {
      set(
        produce((draft) => {
          [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
            ...Object.values(draft.byIds),
            one.data,
          ]);
        }),
      );
    }
    return one;
  },
}));
