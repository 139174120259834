import React from "react";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../../stores/auth";
import Spinner from "../../misc/Spinner";

export default function DropdownProfile() {
  const me = useAuthStore((state) => state.me);
  if (!me) {
    return <Spinner />;
  }
  return (
    <div className="navbar-item navbar-user dropdown">
      <a
        href="#/"
        className="navbar-link dropdown-toggle d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <div className="image image-icon bg-gray-800 text-gray-600">
          <i className="fa fa-user"></i>
        </div>
        <span>
          <span className="d-none d-md-inline">{me.name}</span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        <a href="#/" className="dropdown-item">
          Edit Profile
        </a>
        <a href="#/" className="dropdown-item">
          Settings
        </a>
        <div className="dropdown-divider"></div>
        <Link to="/auth/logout" className="dropdown-item">
          Log Out
        </Link>
      </div>
    </div>
  );
}
