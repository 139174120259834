import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DELIVERY_NOTE_ATTRS,
  DELIVERY_NOTE_STATE,
  useDeliveryNotesStore,
} from "../../stores/delivery_notes";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import { SettingsContext } from "../../config/settings";
import { DEV_MODE } from "../../constants";
import { Role, useAuthStore } from "../../stores/auth";
import { AuthContext } from "../../config/auth";

export default function DeliveryNoteDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const isPrint = location.pathname.startsWith("/print");
  const params = useParams();
  const noteId = Number.parseInt(params.noteId);
  const { whitelabel } = useContext(SettingsContext);

  const [hasRole] = useAuthStore((state) => [state.hasRole], shallow);
  const canReview = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canBill = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canCancel = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canEdit = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);

  const [byIds, allIds, getById, changeState] = useDeliveryNotesStore(
    (state) => [state.byIds, state.allIds, state.getById, state.changeState],
    shallow,
  );

  const isLoading = !allIds.includes(noteId);
  const deliveryNote = byIds[noteId];

  const [invoiceNumber, setInvoiceNumber] = useState("");

  useEffect(() => {
    getById({ id: noteId });
  }, [noteId]);

  useEffect(() => {
    setInvoiceNumber(deliveryNote?.invoice_number || "");
  }, [deliveryNote, noteId]);

  if (isLoading) {
    return <Spinner />;
  }

  const isReviewed = deliveryNote.is_reviewed;
  const isBilled = deliveryNote.is_billed;
  const isCanceled = deliveryNote.is_canceled;

  // ugly but works simple for now
  return (
    <div>
      {!isPrint && (
        <ol className="breadcrumb d-print-none">
          <li className="breadcrumb-item">
            <Link to="/delivery_notes">
              {t("delivery_notes.delivery_notes")}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            {t("common.number_short")} {deliveryNote.number}
          </li>
        </ol>
      )}
      {!isPrint && (
        <h1 className="page-header d-print-none">
          {t("delivery_notes.delivery_note")}{" "}
          {/*<small>weiterer Text hier...</small>*/}
        </h1>
      )}
      <div className="delivery_note" style={isPrint ? { padding: 0 } : {}}>
        <div
          className="delivery_note-company"
          style={isPrint ? { marginBottom: 0 } : {}}
        >
          {!isPrint && (
            <span className="float-end d-print-none">
              {canReview && !isReviewed && (
                <button
                  type="button"
                  onClick={() =>
                    changeState({
                      id: deliveryNote.id,
                      state: DELIVERY_NOTE_STATE.REVIEWED,
                    })
                  }
                  disabled={isReviewed}
                  className="btn btn-sm btn-success mb-10px me-2"
                >
                  <i
                    className={`fa fa-${
                      isReviewed ? "check" : "question-circle"
                    } t-plus-1 fa-fw fa-lg`}
                  />{" "}
                  {t("delivery_notes.states.REVIEWED")}
                </button>
              )}
              {canReview && isReviewed && (
                <Fragment>
                  <div className="btn-group mb-10px me-2">
                    {!isCanceled && (
                      <div className="form-inline">
                        <input
                          className="form-control"
                          placeholder={t("invoices.number")}
                          disabled={isBilled || isCanceled}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          value={invoiceNumber}
                        />
                      </div>
                    )}
                    <button
                      type="button"
                      onClick={() =>
                        changeState({
                          id: deliveryNote.id,
                          state: DELIVERY_NOTE_STATE.BILLED,
                          attrs: {
                            [DELIVERY_NOTE_ATTRS.INVOICE_NUMBER]: invoiceNumber,
                          },
                        })
                      }
                      disabled={isBilled || isCanceled || !invoiceNumber}
                      className="btn btn-sm btn-success"
                    >
                      <i
                        className={`fa fa-${
                          isBilled ? "check" : "question-circle"
                        } t-plus-1 fa-fw fa-lg`}
                      />{" "}
                      {isCanceled
                        ? t("delivery_notes.states.CANCELED")
                        : t("delivery_notes.states.BILLED")}
                    </button>
                    <button
                      type="button"
                      data-bs-toggle="dropdown"
                      className="btn btn-success dropdown-toggle"
                      disabled={isBilled || isCanceled}
                    >
                      <i className="fa fa-caret-down" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <button
                        onClick={() =>
                          changeState({
                            id: deliveryNote.id,
                            state: DELIVERY_NOTE_STATE.CANCELED,
                          })
                        }
                        type="button"
                        className="dropdown-item"
                        disabled={isBilled || isCanceled}
                      >
                        <i
                          className={`fa fa-${
                            isBilled ? "check" : "question-circle"
                          } t-plus-1 fa-fw fa-lg`}
                        />{" "}
                        {t("delivery_notes.states.CANCELED")}
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
              {false && (
                <Link to="#todo" className="btn btn-sm btn-white mb-10px me-2">
                  <i className="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i>{" "}
                  Export als PDF
                </Link>
              )}
              {false && (
                <Link to="#todo" className="btn btn-sm btn-white mb-10px me-2">
                  <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i> Drucken
                </Link>
              )}
              {canEdit && (
                <Link
                  to={
                    isReviewed ? "#" : `/delivery_notes/${deliveryNote.id}/edit`
                  }
                  className={`btn btn-sm btn-white mb-10px me-2 ${
                    isReviewed && "disabled"
                  }`}
                >
                  <i className="fa fa-pencil t-plus-1 fa-fw fa-lg" />{" "}
                  {t("common.edit")}
                </Link>
              )}
              <Link
                to={`/delivery_notes/add?submissionId=${deliveryNote.submission.id}&copyFromId=${deliveryNote.id}`}
                className="btn btn-sm btn-white mb-10px me-2"
              >
                <i className="fa fa-copy t-plus-1 fa-fw fa-lg" />{" "}
                {t("common.copy")}
              </Link>
              <Link
                to={`/print/delivery_notes/${deliveryNote.id}/details`}
                className="btn btn-sm btn-white mb-10px"
              >
                <i className="fa fa-print t-plus-1 fa-fw fa-lg" />{" "}
                {t("common.print")}
              </Link>
            </span>
          )}
          {!isPrint && <span className="d-print-none">{whitelabel.name}</span>}
        </div>
        {isPrint && (
          <address className="m-0 border-bottom">
            {whitelabel.name} • {!!whitelabel.contact && whitelabel.contact} •{" "}
            {!!whitelabel.street && whitelabel.street} •{" "}
            {!!whitelabel.zip && whitelabel.zip}{" "}
            {!!whitelabel.city && whitelabel.city}
          </address>
        )}
        <div className={`row m-0 ${isPrint ? "p-0" : "p-2"} bg-light`}>
          <div className="col p-0">
            <small>{t("customers.customer")}</small>
            {deliveryNote.customer.addresses.map((address) => (
              <address key={address.id} className="mt-5px">
                {deliveryNote.customer.name}
                <br />
                <span className="text-nowrap">{address.street}</span>
                <br />
                {address.zip} {address.city}
                <br />
              </address>
            ))}
            <small>{t("customers.properties.property")}</small>
            {deliveryNote.customer_property.addresses.map((address) => (
              <address key={address.id} className="mt-5px">
                <span className="text-nowrap">{address.street}</span>
                <br />
                {address.zip} {address.city}
                <br />
              </address>
            ))}
          </div>
          <div className="col-auto p-0">
            <small>{t("delivery_notes.delivery_note")}</small>
            <table>
              <tbody>
                <tr>
                  <td className="pe-2">{t("delivery_notes.number")}:</td>
                  <td className="text-nowrap">{deliveryNote.number}</td>
                </tr>
                <tr>
                  <td className="pe-2">{t("customers.number")}:</td>
                  <td className="text-nowrap">
                    {deliveryNote.customer.number}
                  </td>
                </tr>
                <tr>
                  <td className="pe-2">{t("customers.properties.number")}:</td>
                  <td className="text-nowrap">
                    {deliveryNote.customer_property.number}
                  </td>
                </tr>
                <tr>
                  <td className="pe-2">{t("delivery_notes.date")}:</td>
                  <td>{deliveryNote.date}</td>
                </tr>
                <tr>
                  <td className="pe-2">{t("common.expert")}:</td>
                  <td className="text-nowrap">
                    {deliveryNote.created_by.name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="delivery_note-content">
          {isPrint && (
            <div>
              <h4 className="mt-3">
                {t("delivery_notes.delivery_note")} {deliveryNote.number}
              </h4>
              <small>
                {t("common.date.from")} {deliveryNote.date}
              </small>
            </div>
          )}
          <div className="table-responsive">
            <table
              className={`table table-delivery_note ${
                !isPrint && "table-hover"
              }`}
            >
              <thead>
                <tr>
                  <th>{t("items.line")}</th>
                  <th>{t("items.number")}</th>
                  <th>{t("items.name")}</th>
                  <th>{t("items.groups.group")}</th>
                  <th>{t("delivery_notes.next_date_of_inspection")}</th>
                  <th className="text-end">{t("items.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {deliveryNote.items.map((item) => (
                  <tr key={item.id}>
                    <td width={1}>{item.line}</td>
                    <td width={1}>{item.number}</td>
                    <td className="text-wrap">
                      {item.name}
                      {item.attributes.length > 0 && (
                        <table>
                          <tbody>
                            {item.attributes
                              .filter((attr) => !!attr.data.values[0])
                              .map((attr, i) => (
                                <tr
                                  key={i}
                                  className={`${
                                    i % 2 || isPrint || "bg-light"
                                  }`}
                                >
                                  <td className="py-1">{attr.name}:</td>
                                  <td className="ps-4">
                                    {attr.data.values[0]}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </td>
                    <td className="text-wrap">
                      <ul className="list-group list-unstyled">
                        {item.groups.map((group) => (
                          <li key={group.id}>{group.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td width={1}>
                      {!!item.next_date_of_inspection &&
                        item.next_date_of_inspection}
                    </td>
                    <td className="text-end" width={1}>
                      {item.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="">
          {deliveryNote.remark !== "" && (
            <div className="row mb-3">
              <div className="col">
                <div className="text-dark">
                  <h5>{t("menu.remark")}</h5>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: deliveryNote.remark,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {deliveryNote.files.length > 0 && (
            <div className="row mb-3">
              <div className="col">
                <div className="float-start">
                  {deliveryNote.files
                    .filter((file) => file.is_thumbnailable)
                    .map((file) => (
                      <a key={file.id} href={`/v1/files/${file.id}/content`}>
                        <img
                          className="rounded me-2 mb-2 border-end border-bottom"
                          style={{ width: "unset", height: "100px" }}
                          src={`/v1/files/${file.id}/thumbnails?height=100`}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="row mb-3">
            {!!deliveryNote.employee_signature && (
              <div className="col">
                <div className="text-dark">
                  <h5>
                    {t("customers.menu.signature")} {t("common.expert")}
                  </h5>
                  <img
                    src={deliveryNote.employee_signature}
                    className="mt-2"
                    style={{ maxWidth: "150px", pageBreakAfter: "auto" }}
                  />
                  {!!deliveryNote.employee_signature_name && (
                    <div className="mt-2">
                      <small>{deliveryNote.employee_signature_name}</small>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!!deliveryNote.customer_signature ? (
              <div className="col text-dark">
                <div className="float-end text-dark">
                  <h5>
                    {t("customers.menu.signature")} {t("customers.customer")}
                  </h5>
                  <img
                    src={deliveryNote.customer_signature}
                    className="mt-2"
                    style={{ maxWidth: "150px", pageBreakAfter: "auto" }}
                  />
                  {!!deliveryNote.customer_signature_name && (
                    <div className="mt-2">
                      <small>{deliveryNote.customer_signature_name}</small>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="col text-dark">
                <div className="float-end text-dark">
                  <h5>{t("delivery_notes.customer.no_signature")}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("delivery_notes.customer.no_signature_reason"),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <p className="text-center mb-5px fw-bold">
            {t("delivery_notes.footer.thank_you")}
          </p>
          <p className="text-center">
            {!!whitelabel.url && (
              <span className="me-10px">
                <i className="fa fa-fw fa-lg fa-globe"></i> {whitelabel.url}
              </span>
            )}
            {!!whitelabel.phone && (
              <span className="me-10px">
                <i className="fa fa-fw fa-lg fa-phone-volume"></i>{" "}
                {whitelabel.phone}
              </span>
            )}
            {!!whitelabel.fax && (
              <span className="me-10px">
                <i className="fa fa-fw fa-lg fa-fax"></i> {whitelabel.fax}
              </span>
            )}
            {!!whitelabel.email && (
              <span className="me-10px">
                <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                {whitelabel.email}
              </span>
            )}
          </p>
        </div>
      </div>
      {DEV_MODE && !isPrint && (
        <pre
          className="m-t-xl"
          style={{
            fontSize: "1rem",
            padding: ".25rem .5rem",
            overflowX: "scroll",
          }}
        >
          delivery_note: {JSON.stringify(deliveryNote, null, 2)}
        </pre>
      )}
    </div>
  );
}
