import request from "./client";

export const getById = async ({ id }) => {
  const resp = await request.get(`/v1/items/${id}`);
  return { data: resp.body };
};
export const getSome = async ({
  q = "",
  filter = "",
  limit = 10,
  offset = 0,
}) => {
  try {
    const resp = await request.get(
      `/v1/items?limit=${limit}&offset=${offset}&q=${q}&filter=${filter}`,
    );
    const body = resp.body;
    return { total: body.meta.total, data: body.results, error: null };
  } catch (e) {
    if (!e.response) {
      return { total: 0, data: [], error: e };
    }
    return { total: 0, data: [], error: e.response.body };
  }
};

export const updateOne = async ({
  id,
  number,
  name,
  description,
  highlight,
  characteristic,
  inspect_every_x_months,
}) => {
  try {
    const resp = await request.put(`/v1/items/${id}`).send({
      number,
      name,
      description,
      highlight,
      characteristic,
      inspect_every_x_months,
    });
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response };
  }
};
