import { create } from "zustand";
import produce from "immer";
import * as api from "../services/delivery_notes";
import { arrayToByIdsAllIds } from "./utils";

export const DELIVERY_NOTE_STATE = Object.freeze({
  PENDING: "PENDING",
  DELIVERED: "DELIVERED",
  REVIEWED: "REVIEWED",
  BILLED: "BILLED",
  CANCELED: "CANCELED",
});

export const DELIVERY_NOTE_ATTRS = Object.freeze({
  INVOICE_NUMBER: "invoice.number",
});

export const useDeliveryNotesStore = create((set) => ({
  byIds: {},
  allIds: [],
  total: 0,
  totalDue: 0,
  totalReviewable: 0,
  totalBillable: 0,
  fetchSome: async ({ q = "", filter = "", limit = 20, offset = 0 }) => {
    const some = await api.getSome({ q, filter, limit, offset });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds(some.data);
        draft.total = some.total;

        // TODO make pagination work if we keep all data
        //[draft.byIds, draft.allIds] = arrayToByIdsAllIds([...Object.values(draft.byIds), ...some.data]);
        //draft.total = some.total;
      }),
    );
  },
  fetchDueTotal: async () => {
    const some = await api.getSome({ filter: "due" });
    set(
      produce((draft) => {
        draft.totalDue = some.total;
      }),
    );
  },
  fetchBillableTotal: async () => {
    const some = await api.getSome({ filter: "bill" });
    set(
      produce((draft) => {
        draft.totalBillable = some.total;
      }),
    );
  },
  fetchReviewableTotal: async () => {
    const some = await api.getSome({ filter: "review" });
    set(
      produce((draft) => {
        draft.totalReviewable = some.total;
      }),
    );
  },
  changeState: async ({ id, state, attrs = {} }) => {
    const one = await api.changeState({ id, state, attrs });
    if (!!one.data) {
      set(
        produce((draft) => {
          [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
            ...Object.values(draft.byIds),
            one.data,
          ]);
        }),
      );
    }
    return one;
  },
  createOne: async ({
    state,
    submission,
    customer,
    customer_property,
    items,
    uploads,
    remark,
    customer_signature,
    customer_signature_name,
    employee_signature,
    employee_signature_name,
  }) => {
    const one = await api.createOne({
      state,
      submission,
      customer,
      customer_property,
      items,
      uploads,
      remark,
      customer_signature,
      customer_signature_name,
      employee_signature,
      employee_signature_name,
    });
    if (!!one.data) {
      set(
        produce((draft) => {
          [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
            ...Object.values(draft.byIds),
            one.data,
          ]);
        }),
      );
    }
    return one;
  },
  updateOne: async ({
    id,
    state,
    submission,
    customer,
    customer_property,
    items,
    uploads,
    remark,
  }) => {
    const one = await api.updateOne({
      id,
      submission,
      customer,
      customer_property,
      items,
      uploads,
      remark,
    });
    if (!!one.data) {
      set(
        produce((draft) => {
          [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
            ...Object.values(draft.byIds),
            one.data,
          ]);
        }),
      );
    }
    return one;
  },
  getById: async ({ id }) => {
    const one = await api.getById({ id });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds([
          ...Object.values(draft.byIds),
          one.data,
        ]);
      }),
    );
  },
}));
