import { getIn } from "formik";
import { useEffect } from "react";

const Input = ({ field, form, ...props }) => {
  const newProps = {
    ...props,
    className: `${props.className} ${
      getIn(form.errors, field.name) ? "is-invalid" : ""
    }`,
  };
  return <input {...field} {...newProps} />;
};

export default Input;
