import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../config/auth";
import { useAuthStore } from "../../stores/auth";
import { shallow } from "zustand/shallow";
import { Navigate } from "react-router-dom";
import Spinner from "../../components/misc/Spinner";

export default function Logout() {
  const { token, removeToken } = useContext(AuthContext);

  const [logout] = useAuthStore((state) => [state.logout], shallow);
  useEffect(() => {
    logout().then(() => removeToken());
  }, []);

  if (!!token) {
    return <Spinner />;
  }
  return <Navigate to="/" />;
}
