export default {
  auth: {
    unauthorized: "Login fehlgeschlagen",
    remember_me: "Merken",
    sign_in: "Anmelden",
    forgot_password: "Passwort vergessen",
    forgot_password_alert:
      "E-Mail mit Anweisungen zum zurücksetzen wurde versendet",
    password_confirm_error: "Passwörter müssen übereinstimmen",
    submit: "Senden",
    password: "Passwort",
    password_confirm: "Passwort wiederholen",
    reset_password_alert: "Ihr Passwort wurde zurück gesetzt",
    reset_token_expired: "Der Link ist leider abgelaufen",
    password_policy: {
      number: "Passwort muss mindestens eine Zahl enthalten",
      length: "Passwort muss mindestens 8 Zeichen lang sein",
      special: "Passwort muss mindestens ein Sonderzeichzen enthalten",
      uppercase: "Passwort muss mindestens ein Großbuchstaben enthalten",
      lowercase: "Passwort muss mindestens ein Kleinbuchstaben enthalten",
    },
  },
  menu: {
    files: "Bilder & Dateien",
    note: "Notiz",
    notes: "Notizen",
    remark: "Bemerkung",
    delivery_notes: "Lieferscheine",
    home: "Startseite",
    items: "Artikel",
    customers: "Kunden",
    customer_properties: "Objekte",
    help: "Hilfe",
    feedback: "Anfrage",
    settings: "Einstellungen",
    search: "Suche",
    filter: "Filter",
    all: "Alle",
    deleted: "Gelöscht",
    import: "Import",
    export: "Export",
    more: "Mehr",
    add: "Hinzufügen",
    edit: "Ändern",
    next: "Weiter",
    previous: "Zurück",
    from: "von",
    to: "bis",
    cancel: "Abbrechen",
    submit: "Speichern",
    review: "Zur Überprüfung",
    bill: "Nicht Abgerechnet",
    billed: "Abgerechnet",
    canceled: "Storniert",
    inspection: "Zur Wartung",
  },
  items: {
    highlight: "In Dokumentübersicht hervorheben",
    highlight_short: "Hervorheben",

    inspect_every_x_months: "Intervall",
    line: "Pos",
    inspect_interval_months: "Inspektion in Monaten",
    items: "Artikel",
    itemp: "Artikel",
    name: "Artikel",
    amount: "Menge",
    details: "Artikel Details",
    number: "Nummer",
    description: "Beschreibung",
    attributes: {
      choice: "Option",
      menu: {
        add_choice: "Option anlegen",
        select_choice: "Option auswählen",
      },
    },
    groups: {
      group: "Gruppe",
    },

    characteristics: {
      characteristic: "Merkmal",
      DIFFERENT: "Mit unterschiedlichen Merkmalen",
      SIMULAR: "Mit gleichartigen Merkmalen",
      UNIQUE: "Mit einzigartigen Merkmalen",
      short: {
        DIFFERENT: "",
        SIMULAR: "Gleichartig",
        UNIQUE: "Einzigartig",
      },
    },
  },
  customers: {
    new: "Neuer Kunde",
    number: "Kunden Nr.",
    customers: "Kunden",
    customer: "Kunde",
    name: "Kundenname",
    properties: {
      search: "Objekt Suche...",
      street: "Objekt Straße",
      zip: "Objekt Postleitzahl",
      city: "Objekt Stadt",
      district: "Objekt Bezirk",
      name: "Objekt Name",
      number: "Objekt Nr.",
      properties: "Objekte",
      property: "Objekt",
      contact: "Kontakt",
      address: "Adresse",
    },

    menu: {
      search: "Kunde",
      signature: "Unterschrift",
    },
  },
  delivery_notes: {
    delivery_notes: "Lieferscheine",
    delivery_note: "Lieferschein",
    number: "Nummer",
    date: "Datum",
    next_date_of_inspection: "Fällig",
    menu: {
      add: "Hinzufügen",
      add_2: "Anlegen",
      save: "Speichern",
      completed: "Abschließen",
      status: "Status",
    },
    status: {
      due: "Fällig",
    },
    states: {
      PENDING: "Ausstehend",
      DELIVERED: "Geliefert",
      REVIEWED: "Geprüft",
      BILLED: "Abgerechnet",
      CANCELED: "Storniert",
    },

    submissions: {
      submission: "Vorlage",
    },
    alerts: {
      reviewed:
        "Liferschein ist bereits als geprüft markiert und kann nicht mehr verändert werden.",
    },
    footer: {
      thank_you: "Dankeschön für Ihr Vertrauen",
    },
    customer: {
      no_signature: "Keine Kundenunterschrift!",
      no_signature_reason:
        "Kein Unterschriftsberechtigter anwesend<br/>Durchgeführte Arbeiten zuvor besprochen und bestätigt",
    },
  },

  invoices: {
    number: "Rechnungs Nr.",
  },

  address: {
    name: "Name",
    street: "Straße",
    zip: "Postleitzahl",
    district: "Bezirk",
    city: "Stadt",
    menu: {
      from: "Von",
      to: "An",
    },
  },

  errors: {
    types: {
      number: {
        number: "Wert muss einer Zahl entsprechen",
        positive: "Wert muss einer positiven Zahl entsprechen",
      },
    },
  },

  permissions: {
    can_not_edit: "Keine Rechte zum editieren",
  },

  common: {
    number_short: "Nr.",
    full_name: "Vollständiger Name",
    employee: "Mitarbeiter",
    expert: "Mitarbeiter",
    add: "Hinzufügen",
    remove: "Entfernen",
    edit: "Editieren",
    copy: "Kopieren",
    print: "Drucken",
    select: "Auswählen",
    months: "Monate",
    reset: "Zurücksetzten",
    lock: "Sperren",
    unlock: "Entsperren",
    date: {
      from: "vom",
    },
  },

  form: {
    errors: {
      required: "{{attr}} is notwendig",
    },
  },
};
