import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../config/auth";
import { AppSettings } from "./../../config/app-settings.js";
import { useAuthStore } from "../../stores/auth";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import { SettingsContext } from "../../config/settings";

const initialValues = {
  email: "",
  otp: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  otp: Yup.string().optional(),
});

function ForgotPassword() {
  const { t } = useTranslation();
  const settings = useContext(AppSettings);
  const { whitelabel } = useContext(SettingsContext);
  const [alerts, setAlerts] = useState([]);
  const [forgotPassword] = useAuthStore(
    (state) => [state.forgotPassword],
    shallow,
  );

  const [queryParams, setQueryhParams] = useSearchParams();
  const next = queryParams.get("next");

  useEffect(() => {
    settings.handleSetAppSidebarNone(true);
    settings.handleSetAppHeaderNone(true);
    settings.handleSetAppContentClass("p-0");

    return () => {
      settings.handleSetAppSidebarNone(false);
      settings.handleSetAppHeaderNone(false);
      settings.handleSetAppContentClass("");
    };
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    const { data, error } = await forgotPassword({
      email: values.email,
    });
    setAlerts((prevState) => [
      ...prevState,
      { key: "auth.forgot_password_alert", type: "success" },
    ]);
  };

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span> {whitelabel.name}
            </div>
            <small>Außendienst Portal</small>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            {alerts.map((a, i) => (
              <div key={i} className="col">
                <div className={`alert alert-${a.type}`}>{t(a.key)}</div>
              </div>
            ))}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="mb-4">
                    <Field
                      type="email"
                      name="email"
                      className="form-control fs-13px h-45px is-invalid"
                      placeholder="Email Adresse"
                    />
                    <ErrorMessage
                      name="email"
                      render={(error) => (
                        <div className="invalid-feedback">
                          <ul className="list-unstyled">
                            {Array.isArray(error) ? (
                              error.map((e, i) => <li key={i}>{t(e.t)}</li>)
                            ) : (
                              <li>{t(error)}</li>
                            )}
                          </ul>
                        </div>
                      )}
                    />
                  </div>
                  <div className="login-buttons">
                    <button
                      type="submit"
                      className="btn h-45px btn-red d-block w-100 btn-lg"
                      disabled={isSubmitting}
                    >
                      {t("auth.submit")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
