//import request from "./client";
import request from "superagent";

export const getWhitelabel = async (token) => {
  try {
    const resp = await request.get(`/v1/settings/whitelabeling`);
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};

export const getTranslation = async (token) => {
  try {
    const resp = await request.get(`/v1/settings/translation`);
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};

export const getEnv = async (token) => {
  try {
    const resp = await request.get(`/v1/settings/env`);
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};
