import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "paper-css/paper.css";
import { useBodyClass } from "./helper";

export const Print = ({ children }) => {
  useBodyClass(["A4"]);
  return (
    <section className="sheet padding-10mm" style={{ height: "auto" }}>
      <Outlet />
    </section>
  );
};

export default Print;
