import AsyncSelect from "react-select/async";
import React from "react";

const customReactSelectStyle = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
  }),

  control: (defaultStyles, state) => ({
    ...defaultStyles,
    paddingLeft: "0.75rem",
    backgroundColor: "var(--app-component-bg)",
    border: "1px solid #ced4da",
    borderColor: state.isFocused ? "#67abe9" : undefined,
    boxShadow: state.isFocused
      ? "0 0 0 0.25rem rgba(52, 143, 226, 0.25)"
      : undefined,
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    fontWeight: "600",
    fontSize: "13px",
    height: "45px",
  }),
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
  }),
  valueContainer: (defaultStyles, state) => ({
    ...defaultStyles,
    padding: "0",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#6c757d",
      opacity: 1,
    };
  },
};

export default function AsyncSelectWrapper({
  inputRef,
  onChange,
  ...otherProps
}) {
  const handleChange = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        target: inputRef,
        value: "",
      };
    }
    onChange(event);
  };

  return (
    <AsyncSelect
      styles={customReactSelectStyle}
      isClearable
      ref={inputRef}
      onChange={handleChange}
      {...otherProps}
    />
  );
}
