import React, { useContext } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import AppRoute from "./config/app-route.jsx";
import { AuthContext, AuthProvider } from "./config/auth";
import { SettingsProvider } from "./config/settings";
import "./translations/i18n";

// bootstrap
import "bootstrap";

// css
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  let element = useRoutes(AppRoute);
  return element;
}

root.render(
  <BrowserRouter>
    <SettingsProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SettingsProvider>
  </BrowserRouter>,
);
