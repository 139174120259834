import React, { useState, Fragment } from "react";
import * as tus from "tus-js-client";
import { bytesToSize } from "../../stores/utils";
import { useTranslation } from "react-i18next";

export default function TusJsClientWrapper({
  hideFinished = false,
  onSuccess = () => {},
  metadata = {},
  asCamera = false,
  accept = "image/*",
}) {
  const { t } = useTranslation();
  const [parallel, setParallel] = useState(1);
  const [files, setFiles] = useState({});

  let fileInputRef;

  const handleTusUpload = (file) => {
    const upload = new tus.Upload(file, {
      endpoint: "/v1/uploads/",
      headers: {
        //"X-CSRFToken": window.csrf_token,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      removeFingerprintOnSuccess: true, // delete localstorage fingerprint after success
      uploadDataDuringCreation: false, // upload after creation
      retryDelays: [0, 1000, 3000, 5000, 10000, 20000, 30000, 60000],
      metadata: {
        filename: file.name,
        filetype: file.type,
        ...metadata,
      },
      onError: (error) => {
        setFiles((prevState) => ({
          ...prevState,
          [file.name]: { ...prevState[file.name], error: error },
        }));
      },
      onProgress: (uploadedBytes, totalBytes) => {
        setFiles((prevState) => ({
          ...prevState,
          [file.name]: {
            ...prevState[file.name],
            uploadedBytes: uploadedBytes,
            totalBytes: totalBytes,
            progress: ((uploadedBytes / totalBytes) * 100).toFixed(2),
          },
        }));
      },
      onSuccess: () => {
        //setUploadUrl(upload.url);
        setTimeout(
          () =>
            setFiles((prevState) => ({
              ...prevState,
              [file.name]: { ...prevState[file.name], finished: true },
            })),
          500,
        );
        onSuccess({
          url: upload.url,
          hash: upload.hash, // we added this in onAfterResponse from x-content-hash header
          name: upload.file.name,
          size: upload.file.size,
          type: upload.file.type,
        });
      },
      //onBeforeRequest: function (req) {
      //  //var xhr = req.getUnderlyingObject()
      //  //xhr.withCredentials = true
      //},
      onShouldRetry: function (err, retryAttempt, options) {
        var status = err.originalResponse
          ? err.originalResponse.getStatus()
          : 0;
        if (status === 401 || status === 403 || status === 404) {
          return false;
        }
        return true;
      },
      onUploadUrlAvailable: function () {
        setFiles((prevState) => ({
          ...prevState,
          [file.name]: {
            ...prevState[file.name],
            url: upload.url,
          },
        }));
      },
      onAfterResponse: function (req, res) {
        const hash = res.getHeader("X-Content-Hash");
        if (!!hash) {
          Object.assign(upload, { hash: hash });
          setFiles((prevState) => ({
            ...prevState,
            [file.name]: {
              ...prevState[file.name],
              hash: hash,
            },
          }));
        }
      },
    });

    upload.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
    });
    upload.start();
  };

  const handleUploadFiles = (files) => {
    //files.map(file => setFiles(prevState => ({prevState, [file.name]: { name: file.name, totalBytes: file.size, uploadedBytes: 0, progress: 0, error: null}})))
    const filesByName = files.reduce((obj, file) => {
      obj[file.name] = {
        name: file.name,
        totalBytes: file.size,
        uploadedBytes: 0,
        progress: 0,
        finished: false,
        error: null,
        hash: null,
        url: null,
      };
      return obj;
    }, {});
    setFiles((prevState) => ({ ...prevState, ...filesByName }));
    files.some((file) => handleTusUpload(file));
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  return (
    <ul className="list-unstyled mb-0 border-0">
      <li>
        <input
          type="file"
          hidden={true}
          multiple={true}
          onChange={handleFileEvent}
          //accept=".pdf,.rtf,.odt,.doc,.docx,.xls,.xlsx,.jpeg,.jpg,.png"
          accept={accept}
          capture={asCamera}
          ref={(ref) => (fileInputRef = ref)}
        />
        <button
          className="btn btn-success w-100"
          type="button"
          onClick={() => fileInputRef.click()}
        >
          {t("menu.files")}
        </button>
      </li>
      {Object.values(files)
        .filter((f) => (hideFinished ? !f.finished : true))
        .map((f) => (
          <li key={f.name} className="mt-2">
            <h5>
              {f.name}{" "}
              <span className="pull-right">
                {bytesToSize(f.uploadedBytes)} / {bytesToSize(f.totalBytes)}
              </span>
            </h5>
            {!!f.error && (
              <div className="alert alert-danger">{f.error.message}</div>
            )}
            <div className="progress bg-red">
              <div className="progress-bar ps-1">
                {f.progress < 100 ? f.progress : f.finished ? 100 : 99.99}%
              </div>
              <div
                className={`progress-bar ${
                  f.finished ? "" : "progress-bar-striped progress-bar-animated"
                } progress-bar-navy-light`}
                style={{ width: `${f.progress}%` }}
              />
              {f.finished && (
                <div className="progress-bar">
                  <i className="fa fa-check pe-1" />
                </div>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
}
