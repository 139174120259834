//import request from "./client";
import request from "superagent";

export const getMe = async (token) => {
  try {
    const resp = await request
      .get(`/v1/users/me`)
      .set("Authorization", `Bearer ${token}`);
    const body = resp.body;
    return { data: body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};
export const login = async ({ username, password }) => {
  //grant_type=&username=${values.email}&password=${values.password}&scope=&client_id=&client_secret=
  try {
    const resp = await request.post(`/v1/auth/token`).type("form").send({
      grant_type: "",
      username: username,
      password: password,
      scope: "",
      client_id: "",
      client_secret: "",
    });
    return { data: resp.body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};

export const logout = async () => {
  try {
    const resp = await request.post(`/v1/auth/logout`);
    return { data: resp.body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};

export const forgotPassword = async ({ email }) => {
  try {
    const resp = await request.post(`/v1/auth/forgot_password`).send({
      email: email,
    });
    return { data: resp.body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};

export const resetPassword = async ({ token, password }) => {
  try {
    const resp = await request.post(`/v1/auth/reset_password`).send({
      token: token,
      password: password,
    });
    return { data: resp.body, error: null };
  } catch (e) {
    if (!e.response) {
      return { data: null, error: e };
    }
    return { data: null, error: e.response.body };
  }
};
