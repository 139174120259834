import { create } from "zustand";
import produce from "immer";
import * as api from "../services/delivery_note_submissions";
import { arrayToByIdsAllIds } from "./utils";

export const useDeliveryNoteSubmissionsStore = create((set) => ({
  byIds: {},
  allIds: [],
  selectedId: null,
  total: 0,
  fetchSome: async ({ q = "", limit = 20, offset = 0 }) => {
    const some = await api.getSome({ q, limit, offset });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds(some.data);
        draft.total = some.total;
      }),
    );
  },
  getById: async ({ id }) => {
    const one = await api.getById({ id });
    set(
      produce((draft) => {
        [draft.byIds, draft.allIds] = arrayToByIdsAllIds([one.data]);
        draft.selectedId = one.data.id;
      }),
    );
  },
  setSelectedId: async ({ id }) => {
    set(() => ({ selectedId: id }));
  },
}));
