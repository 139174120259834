import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDeliveryNotesStore } from "../../stores/delivery_notes";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import { useDeliveryNoteSubmissionsStore } from "../../stores/delivery_note_submissions";
import Spinner from "../../components/misc/Spinner";
import { Role, useAuthStore } from "../../stores/auth";

const FILTER = {
  ALL: "",
  DUE: "due",
  REVIEW: "review",
  BILL: "bill",
  BILLED: "billed",
  CANCELED: "canceled",
};

function DeliveryNote({ delivery_note }) {
  const { t } = useTranslation();

  let badgeColor = "success";
  //switch (delivery_note.state) {
  //  case "pending":
  //    badgeColor = "warning";
  //    break;
  //  case "delivered":
  //    badgeColor = "lime";
  //    break;
  //  case "completed":
  //    badgeColor = "green";
  //    break;
  //}

  return (
    <tr>
      <td className="w-10px align-middle">
        <div className="form-check">
          <input type="checkbox" className="form-check-input" id="product1" />
          <label className="form-check-label" htmlFor="product1"></label>
        </div>
      </td>
      <td>
        <Link to={`${delivery_note.id}/details`} className="fw-bold">
          {delivery_note.number}
        </Link>
      </td>
      <td>{delivery_note.date}</td>
      <td>{delivery_note.customer.number}</td>
      <td>{delivery_note.invoice_number}</td>
      <td>
        <ul className="list-group list-unstyled">
          {delivery_note.customer_property.addresses.map((address) => (
            <li key={address.id}>
              <ul className="list-group list-unstyled">
                {!!address.street && <li>{address.street}</li>}
                {(!!address.zip || !!address.city) && (
                  <li>
                    {address.zip} {address.city}
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </td>

      <td>
        <ul className="list-group list-unstyled">
          {delivery_note.state_transitions.slice(-1).map((transition) => (
            <li key={transition.id}>
              <span
                className={`badge border border-${badgeColor} text-${badgeColor} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}
              >
                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                {t(`delivery_notes.states.${transition.state}`)}
              </span>
            </li>
          ))}
        </ul>
      </td>
      <td>
        <ul className="list-group list-unstyled">
          {delivery_note.items
            .filter((item) => item.origin?.highlight)
            .map((item) => (
              <li key={item.id}>
                <span>
                  {item.number}|{item.name}|{item.amount}x
                </span>
              </li>
            ))}
        </ul>
      </td>
      <td>{delivery_note.submission?.name}</td>
      <td>{delivery_note.created_by.name}</td>
    </tr>
  );
}

function DeliveryNotes() {
  const { t, i18n } = useTranslation();
  const [queryParams, setQueryhParams] = useSearchParams();

  const [hasRole] = useAuthStore((state) => [state.hasRole], shallow);
  const canReview = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canBill = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canCancel = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canEdit = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);

  const [
    deliveryNotesByIds,
    deliveryNotesAllIds,
    deliveryNotesTotal,
    deliveryNotesTotalDue,
    deliveryNotesTotalReviewable,
    deliveryNotesTotalBillable,
    deliveryNotesFetchSome,
    deliveryNotesFetchDueTotal,
    deliveryNotesFetchReviewableTotal,
    deliveryNotesFetchBillableTotal,
  ] = useDeliveryNotesStore(
    (state) => [
      state.byIds,
      state.allIds,
      state.total,
      state.totalDue,
      state.totalReviewable,
      state.totalBillable,
      state.fetchSome,
      state.fetchDueTotal,
      state.fetchReviewableTotal,
      state.fetchBillableTotal,
    ],
    shallow,
  );

  const deliveryNotes = deliveryNotesAllIds.map((id) => deliveryNotesByIds[id]);

  const [
    selectedSubmissionId,
    submissionsByIds,
    submissionsAllIds,
    fetchSomeSubmissions,
    setSelectedSubmissionId,
  ] = useDeliveryNoteSubmissionsStore(
    (state) => [
      state.selectedId,
      state.byIds,
      state.allIds,
      state.fetchSome,
      state.setSelectedId,
    ],
    shallow,
  );

  const selectedSubmission = Number.isInteger(selectedSubmissionId)
    ? submissionsByIds[selectedSubmissionId]
    : submissionsByIds[submissionsAllIds[0]];

  const search = queryParams.get("q") || "";
  const filter = queryParams.get("filter") || FILTER.ALL;
  const limit = parseInt(queryParams.get("limit")) || 10;
  const pages = Math.ceil(deliveryNotesTotal / limit);
  const page = parseInt(queryParams.get("page")) || 0;
  const offset = page * limit;

  useEffect(() => {
    deliveryNotesFetchSome({ q: search, filter, limit, offset });
  }, [search, page, limit, filter]);

  useEffect(() => {
    deliveryNotesFetchDueTotal();
  }, []);

  useEffect(() => {
    if (canBill) {
      deliveryNotesFetchBillableTotal();
    }
  }, []);

  useEffect(() => {
    if (canReview) {
      deliveryNotesFetchReviewableTotal();
    }
  }, []);

  useEffect(() => {
    fetchSomeSubmissions({ q: "", limit: 20, offset: 0 });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setQueryhParams(`page=${0}&q=${e.target.value}&filter=${filter}`);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3 mx-3 mx-md-0">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/delivery_notes">
                {t("delivery_notes.delivery_notes")}
              </Link>
            </li>
          </ol>
          <h1 className="page-header mb-0">
            {t("delivery_notes.delivery_notes")}
          </h1>
        </div>
        {!!selectedSubmission && (
          <div className="ms-auto">
            <div className="btn-group me-1 mb-1">
              <Link
                to={`add?submissionId=${selectedSubmission.id}`}
                className="btn btn-red"
              >
                {selectedSubmission.name}
              </Link>
              <button
                type="button"
                data-bs-toggle="dropdown"
                className="btn btn-red dropdown-toggle"
              >
                <i className="fa fa-caret-down" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {submissionsAllIds
                  .filter((sid) => sid !== selectedSubmission.id)
                  .map((sid) => submissionsByIds[sid])
                  .map((submission) => (
                    <Link
                      key={submission.id}
                      to={`add?submissionId=${submission.id}`}
                      onClick={() =>
                        setSelectedSubmissionId({ id: submission.id })
                      }
                      className="dropdown-item"
                    >
                      {submission.name}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mb-3 mx-3 mx-md-0 d-sm-flex fw-bold">
        <div className="mt-sm-0 mt-2">
          <a href="#/" className="text-dark text-decoration-none">
            <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.export")}
          </a>
        </div>
        <div className="ms-sm-4 ps-sm-1 mt-sm-0 mt-2">
          <a href="#/" className="text-dark text-decoration-none">
            <i className="fa fa-upload fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.import")}
          </a>
        </div>
        <div className="ms-sm-4 ps-sm-1 mt-sm-0 mt-2 dropdown-toggle">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            className="text-dark text-decoration-none"
          >
            <i className="fa fa-cog fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            {t("menu.more")} <b className="caret"></b>
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              Action
            </a>
            <a className="dropdown-item" href="#/">
              Another action
            </a>
            <a className="dropdown-item" href="#/">
              Something else here
            </a>
            <div role="separator" className="dropdown-divider"></div>
            <a className="dropdown-item" href="#/">
              Separated link
            </a>
          </div>
        </div>
      </div>

      <div className="card border-0">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
          <li className="nav-item me-2">
            <button
              onClick={() => setQueryhParams(FILTER.ALL)}
              className={`nav-link px-2 ${filter === FILTER.ALL && "active"}`}
            >
              {t(`menu.all`)}
            </button>
          </li>
          <li className="nav-item me-2">
            <button
              className={`nav-link px-2 ${filter === FILTER.DUE && "active"}`}
              onClick={() => setQueryhParams(`filter=${FILTER.DUE}`)}
            >
              {t(`menu.inspection`)}{" "}
              {deliveryNotesTotalDue > 0 && (
                <span className="badge bg-red fs-12px rounded-pill ms-auto pt-4px pb-5px px-8px">
                  {deliveryNotesTotalDue}
                </span>
              )}
            </button>
          </li>

          {canReview && (
            <li className="nav-item me-2">
              <button
                onClick={() => setQueryhParams(`filter=${FILTER.REVIEW}`)}
                className={`nav-link px-2 ${
                  filter === FILTER.REVIEW && "active"
                }`}
              >
                {t(`menu.review`)}{" "}
                {deliveryNotesTotalReviewable > 0 && (
                  <span className="badge bg-success fs-12px rounded-pill ms-auto pt-4px pb-5px px-8px">
                    {deliveryNotesTotalReviewable}
                  </span>
                )}
              </button>
            </li>
          )}
          {canBill && (
            <li className="nav-item me-2">
              <button
                onClick={() => setQueryhParams(`filter=${FILTER.BILL}`)}
                className={`nav-link px-2 ${
                  filter === FILTER.BILL && "active"
                }`}
              >
                {t(`menu.bill`)}{" "}
                {deliveryNotesTotalBillable > 0 && (
                  <span className="badge bg-success fs-12px rounded-pill ms-auto pt-4px pb-5px px-8px">
                    {deliveryNotesTotalBillable}
                  </span>
                )}
              </button>
            </li>
          )}
          {canBill && (
            <li className="nav-item me-2">
              <button
                onClick={() => setQueryhParams(`filter=${FILTER.BILLED}`)}
                className={`nav-link px-2 ${
                  filter === FILTER.BILLED && "active"
                }`}
              >
                {t(`menu.billed`)}
              </button>
            </li>
          )}
          {canCancel && (
            <li className="nav-item me-2">
              <button
                onClick={() => setQueryhParams(`filter=${FILTER.CANCELED}`)}
                className={`nav-link px-2 ${
                  filter === FILTER.CANCELED && "active"
                }`}
              >
                {t(`menu.canceled`)}
              </button>
            </li>
          )}
        </ul>
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("menu.filter")} <b className="caret"></b>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#/">
                  Action
                </a>
                <a className="dropdown-item" href="#/">
                  Another action
                </a>
                <a className="dropdown-item" href="#/">
                  Something else here
                </a>
                <div role="separator" className="dropdown-divider"></div>
                <a className="dropdown-item" href="#/">
                  Separated link
                </a>
              </div>
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control ps-35px bg-light"
                    placeholder={t("menu.search") + "..."}
                    onChange={handleSearch}
                    value={search}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("delivery_notes.number")}</th>
                    <th>{t("delivery_notes.date")}</th>
                    <th>{t("customers.number")}</th>
                    <th>{t("invoices.number")}</th>
                    <th>{t("customers.properties.property")}</th>
                    <th>{t("delivery_notes.menu.status")}</th>
                    <th>{t("items.items")}</th>
                    <th>{t("delivery_notes.submissions.submission")}</th>
                    <th>{t("common.expert")}</th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryNotes.map((deliveryNote) => (
                    <DeliveryNote
                      key={deliveryNote.id}
                      delivery_note={deliveryNote}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            {false && (
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nr.</th>
                      <th>Artikel</th>
                      <th>Gruppen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryNotes.map((deliveryNote) => (
                      <DeliveryNote
                        key={deliveryNote.id}
                        delivery_note={deliveryNote}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                {limit * page} {t("menu.to")}{" "}
                {Math.min(limit * (page + 1), deliveryNotesTotal)}{" "}
                {t("menu.from")} {deliveryNotesTotal}{" "}
                {t("delivery_notes.delivery_notes")}
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li className={`page-item ${page === 0 && " active"}`}>
                  <Link
                    className={`page-link `}
                    to={`/delivery_notes?page=0&q=${search}&filter=${filter}`}
                  >
                    1
                  </Link>
                </li>
                {pages > 8 && (
                  <li className={`page-item ${page < 1 && "disabled"}`}>
                    <Link
                      className={`page-link `}
                      to={`/delivery_notes?page=${
                        page - 1
                      }&q=${search}&filter=${filter}`}
                    >
                      {"<"}
                    </Link>
                  </li>
                )}
                {Array(pages)
                  .fill(0)
                  .map((_, i) => (
                    <li
                      key={`page-${i}`}
                      className={`page-item ${page === i && " active"}`}
                    >
                      <Link
                        className="page-link"
                        to={`/delivery_notes?page=${i}&q=${search}&filter=${filter}`}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))
                  .slice(
                    Math.max(Math.min(page - 4, pages - 8), 1),
                    Math.min(Math.max(8, page + 5), pages - 1),
                  )}
                {pages > 8 && (
                  <li
                    className={`page-item ${
                      page + 1 > pages - 1 && "disabled"
                    }`}
                  >
                    <Link
                      className={`page-link `}
                      to={`/delivery_notes?page=${
                        page + 1
                      }&q=${search}&filter=${filter}`}
                    >
                      {">"}
                    </Link>
                  </li>
                )}

                {pages > 1 && (
                  <li className={`page-item`}>
                    <Link
                      className={`page-link  ${
                        page === pages - 1 && " active"
                      }`}
                      to={`/delivery_notes?page=${
                        pages - 1
                      }&q=${search}&filter=${filter}`}
                    >
                      {pages}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryNotes;
