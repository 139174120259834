import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import Spinner from "../../components/misc/Spinner";
import React, { Fragment, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import Input from "../../components/form/Input";
import { Role, useAuthStore } from "../../stores/auth";
import * as Yup from "yup";
import { DEV_MODE } from "../../constants";
import { useCustomerStore } from "../../stores/customers";
import { ITEM_CHARACTERISTIC, useItemStore } from "../../stores/items";
import ReactQuill from "react-quill";

const createInitialValues = (me, item, isAddMode) => ({
  item: {
    id: item?.id || "",
    number: item?.number || "",
    name: item?.name || "",
    description: item?.description || "",
    highlight: item?.highlight || false,
    characteristic: item?.characteristic || ITEM_CHARACTERISTIC.DIFFERENT,
    inspect_every_x_months: item?.inspect_every_x_months || "",
  },
});

const createValidationSchema = (t) =>
  Yup.object().shape({
    item: Yup.object().shape({
      inspect_every_x_months: Yup.number()
        .positive(t("errors.types.number.positive"))
        .nullable()
        .optional()
        .typeError(t("errors.types.number.number")),
    }),
  });

function ItemAddEdit() {
  const { t } = useTranslation();
  const params = useParams();
  const itemId = Number.parseInt(params.itemId);
  const isAddMode = !itemId;
  const navigate = useNavigate();

  const [me, hasRole] = useAuthStore(
    (state) => [state.me, state.hasRole],
    shallow,
  );
  const canView = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);
  const canEdit = hasRole(Role.ADMINISTRATOR) || hasRole(Role.MANAGER);

  const [byIds, allIds, getById, updateOne] = useItemStore(
    (state) => [state.byIds, state.allIds, state.getById, state.updateOne],
    shallow,
  );

  const item = Number.isInteger(itemId) ? byIds[itemId] : null;

  useEffect(() => {
    if (!!itemId) {
      getById({ id: itemId });
    }
  }, [itemId]);
  const handleSubmit = async (values, { setErrors }) => {
    const func = isAddMode ? alert("Not impl.") : updateOne;
    const { error } = await func({
      id: itemId,
      number: values.item.number,
      name: values.item.name,
      description: values.item.description,
      highlight: values.item.highlight,
      characteristic: values.item.characteristic,
      inspect_every_x_months: values.item.inspect_every_x_months || null,
    });

    if (!!error) {
      //error.detail.forEach(d => setErrors)
      alert(JSON.stringify(error.text));
      //console.log(error)
    } else {
      navigate(`/items`);
    }
  };

  if (Number.isInteger(itemId) && !item) {
    return <Spinner />;
  }

  if (!canView) {
    navigate("/");
  }

  const canSubmit = canEdit;

  return (
    <div>
      <div className="d-flex align-items-center mb-3 mx-3 mx-md-0">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/customer_properties">{t("items.items")}</Link>
            </li>
            <li className="breadcrumb-item active">
              <i className="fa fa-arrow-back"></i>
              {isAddMode ? (
                t("menu.add")
              ) : (
                <span>
                  {t("common.number_short")} {item.number}
                </span>
              )}
            </li>
          </ol>
          <h1 className="page-header mb-0">{t("items.items")}</h1>
        </div>
      </div>

      {!canSubmit && (
        <div className="alert alert-danger">
          {t("permissions.can_not_edit")}
        </div>
      )}

      <Formik
        initialValues={createInitialValues(me, item, isAddMode)}
        validationSchema={createValidationSchema(t)}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <div className="row">
              <div className="col">
                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3">
                    <i className="fa fa-cog fa-lg fa-fw text-dark text-opacity-50 me-1"></i>{" "}
                    {t("items.item")}
                  </div>
                  <div className="card-body">
                    <div className="row mb-3 gx-3">
                      <div className="col-md-3 mb-3 mb-md-0">
                        <label className="form-label" htmlFor="item.number">
                          {t("items.number")}
                        </label>
                        <Field
                          component={Input}
                          name="item.number"
                          className="form-control fs-13px h-45px"
                          disabled
                        />
                        <ErrorMessage
                          name="item.number"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                      <div className="col-md">
                        <label className="form-label" htmlFor="item.name">
                          {t("items.name")}
                        </label>
                        <Field
                          component={Input}
                          name="item.name"
                          className="form-control fs-13px h-45px"
                          disabled={!canEdit}
                        />
                        <ErrorMessage
                          name="item.name"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gx-3">
                      <div className="col-md-3">
                        <label
                          className="form-label"
                          htmlFor="item.inspect_every_x_months"
                        >
                          {t("items.inspect_every_x_months")}
                        </label>
                        <Field
                          component={Input}
                          name="item.inspect_every_x_months"
                          className="form-control fs-13px h-45px"
                          disabled={!canEdit}
                        ></Field>
                        <ErrorMessage
                          name="item.inspect_every_x_months"
                          render={(msg) => (
                            <div className="invalid-feedback">{t(msg)}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gx-3">
                      <div className="col">
                        <label
                          className="form-label"
                          htmlFor="item.description"
                        >
                          {t("items.description")}
                        </label>
                        <div
                          className={`form-control p-0 overflow-hidden ${
                            !canEdit ? "bg-light" : ""
                          }`}
                        >
                          <ReactQuill
                            value={values.item.description}
                            onChange={(text) =>
                              setFieldValue("item.description", text)
                            }
                            className="border-0 h-200px mb-40px"
                            readOnly={!canEdit}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 gx-3">
                      <div className="col-12 mb-2">
                        <Field
                          type="checkbox"
                          name="item.highlight"
                          className="form-check-input me-2"
                          disabled={!canEdit}
                        />
                        <label className="form-label" htmlFor="item.highlight">
                          {t("items.highlight")}
                        </label>
                      </div>
                      <div className="col-12">
                        {Object.values(ITEM_CHARACTERISTIC).map((v) => (
                          <div className="form-check" key={v}>
                            <Field
                              type="radio"
                              name="item.characteristic"
                              value={v}
                              className="form-check-input"
                              disabled={!canEdit}
                            />
                            <label
                              className="form-label form-check-label"
                              htmlFor="item.characteristic"
                            >
                              {t(`items.characteristics.${v}`)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card border-0 mb-4">
                  <div className="card-header h6 mb-0 bg-none p-3"></div>
                  <div className="card-body">
                    <div className="btn btn-group d-flex">
                      <button
                        className="btn btn-success w-100"
                        disabled={isSubmitting || !canSubmit}
                      >
                        {t("menu.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {DEV_MODE && (
              <pre
                className="m-t-xl"
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ItemAddEdit;
