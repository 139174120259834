import { create } from "zustand";
import produce from "immer";
import * as api from "../services/settings";

export const useSettingsStore = create((set, get) => ({
  env: null,
  translation: null,
  whitelabel: null,
  isLoading: true,
  fetchWhitelabeling: async () => {
    set(
      produce((draft) => {
        draft.isLoading = true;
      }),
    );
    const resp = await api.getWhitelabel();
    set(
      produce((draft) => {
        draft.whitelabel = resp.data;
        draft.isLoading = false;
      }),
    );
    return resp;
  },
  fetchTranslation: async () => {
    set(
      produce((draft) => {
        draft.isLoading = true;
      }),
    );
    const resp = await api.getTranslation();
    set(
      produce((draft) => {
        draft.translation = resp.data;
        draft.isLoading = false;
      }),
    );
    return resp;
  },
  fetchEnv: async () => {
    set(
      produce((draft) => {
        draft.isLoading = true;
      }),
    );
    const resp = await api.getEnv();
    set(
      produce((draft) => {
        draft.env = resp.data;
        draft.isLoading = false;
      }),
    );
    return resp;
  },
}));
