import React from "react";
import { Link } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { slideToggle } from "./../../composables/slideToggle.js";
import { useTranslation, withTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/auth";
import Spinner from "../misc/Spinner";

export default function SidebarProfile() {
  const { t } = useTranslation();
  const me = useAuthStore((state) => state.me);

  const handleProfileExpand = (e) => {
    e.preventDefault();

    var targetSidebar = document.querySelector(
      ".app-sidebar:not(.app-sidebar-end)",
    );
    var targetMenu = e.target.closest(".menu-profile");
    var targetProfile = document.querySelector("#appSidebarProfileMenu");
    var expandTime =
      targetSidebar &&
      targetSidebar.getAttribute("data-disable-slide-animation")
        ? 0
        : 250;

    if (targetProfile) {
      if (targetProfile.style.display === "block") {
        targetMenu.classList.remove("active");
      } else {
        targetMenu.classList.add("active");
      }
      slideToggle(targetProfile, expandTime);
      targetProfile.classList.toggle("expand");
    }
  };
  if (!me) {
    return <Spinner />;
  }

  return (
    <AppSettings.Consumer>
      {({ appSidebarMinify }) => (
        <div className="menu">
          <div className="menu-profile">
            <Link
              to="/"
              onClick={handleProfileExpand}
              className="menu-profile-link"
            >
              <div className="menu-profile-cover with-shadow"></div>
              <div className="menu-profile-image menu-profile-image-icon bg-gray-900 text-gray-600">
                <i className="fa fa-user"></i>
              </div>
              <div className="menu-profile-info">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">{me.name}</div>
                  <div className="menu-caret ms-auto"></div>
                </div>
              </div>
            </Link>
          </div>
          <div id="appSidebarProfileMenu" className="collapse">
            <div className="menu-item pt-5px">
              <Link to="/" className="menu-link">
                <div className="menu-icon">
                  <i className="fa fa-cog"></i>
                </div>
                <div className="menu-text">{t("menu.settings")}</div>
              </Link>
            </div>
            <div className="menu-item">
              <Link to="/" className="menu-link">
                <div className="menu-icon">
                  <i className="fa fa-pencil-alt"></i>
                </div>
                <div className="menu-text">{t("menu.feedback")}</div>
              </Link>
            </div>
            <div className="menu-item pb-5px">
              <Link to="/" className="menu-link">
                <div className="menu-icon">
                  <i className="fa fa-question-circle"></i>
                </div>
                <div className="menu-text">{t("menu.help")}</div>
              </Link>
            </div>
            <div className="menu-divider m-0"></div>
          </div>
        </div>
      )}
    </AppSettings.Consumer>
  );
}
